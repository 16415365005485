import React, { useState } from 'react';

function Breytalykilordi(props) {
    const id = props.uid;
    const [nyttlykil, setNyttlykil] = useState("");
    const [nyttlykilaftur, setNyttlykilaftur] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (nyttlykil.length < 8) {
            setMessage("Lykilorð verður að vera minnst 8 stafir á lengd!");
        } else {

            if (nyttlykil === nyttlykilaftur) {
                try {
                    let res = await fetch(global.config.api.url.live + "/breytalykilordi", {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        crossdomain: true,
                        body: JSON.stringify({
                            uid: id,
                            password: nyttlykil,
                        }),
                    });

                    let resJson = await res.json();
                    console.log(resJson);
                    if (res.status === 201) {
                        setNyttlykil("");
                        setNyttlykilaftur("");
                        setMessage("Þetta gekk allt eins og í sögu!");
                    } else {
                        setMessage("Ekki tókst að búa til notanda!");
                    }
                } catch (err) {
                    console.log(err);
                }

            } else {
                setMessage("Lykilorðin passa ekki!");
            }
        }
    }

    return (
        <div className="breytalykilordi">
            <h3>Breyta lykilorði</h3>
            <form onSubmit={handleSubmit}>
            <input type="password" value={nyttlykil} placeholder="Nýtt lykilorð" onChange={(e) => setNyttlykil(e.target.value)} />
            <input type="text" value={nyttlykilaftur} placeholder="Staðfesta lykilorð" onChange={(e) => setNyttlykilaftur(e.target.value)} />
            <button>Vista</button>
            {message}
            </form>
        </div>
    )
}

export default Breytalykilordi;