import React from 'react'

function ClosedUrgency() {
    return (
        <div className="formid">
            <div className='formfield'>Kast endaði þann</div>
            <div className='formvalue'>Dagur</div>

            <div className='formfield'>Hversu lengi stóð kastið?</div>
            <div className='formvalue'>lengdkasts</div>

            <div className='formfield'>Hvað var gert?</div>
            <div className='formvalue'>hvadvargert</div>

            <div className='formfield'>Hversu lengi var lyfið að virka þar til einkenni voru bærileg? Frá lyfjagjöf:</div>
            <div className='formvalue'>virkni</div>
            <div className='formfield'>Hversu lengi varstu fjarveru frá vinnu, námi eða getu til að sinna athöfnum daglegs lífs?</div>
            <div className='formvalue'>fjarverafravinnu</div>
        </div>
    )
}

export default ClosedUrgency