import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken} from '../../Utils/Common';
import TreatmentOfSeizures from './user/components/TreatmentOfSeizures';
import MedicineWeeks from './user/components/MedicineWeeks';
import { decrypt } from '../helpers/decrypt'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class User extends Component {

    state = { posts: [], isLoaded: false };
    componentDidMount () {
        axios.get(global.config.api.url.live + '/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded} = this.state;
        console.log(posts);
        if (isLoaded) {
            let kynid = "Annað";
            if (posts[0].kyn === 1) {
                kynid = "Karl";
            } else if (posts[0].kyn === 2) {
                kynid = "Kona";
            }
            return (
                <div>
                    <Topbar title="menu.mypage" heiti=""/>
                    <main>
                        <div className="yfirlit">
                            <div className="fiftycent">
                                <h3 className="section_heading"> {t('home.general')}</h3>
                                <table className="user_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nafn</td>
                                            <td>{decrypt(posts[0].name) || null}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Kennitala</td>
                                            <td>{decrypt(posts[0].ssn) || null}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Kyn</td>
                                            <td>{kynid}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Netfang</td>
                                            <td>{decrypt(posts[0].netfang) || null}</td>
                                            <td><button><i className="fa-light fa-pen-to-square"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td>Símanúmer</td>
                                            <td>{decrypt(posts[0].tel) || null}</td>
                                            <td><button><i className="fa-light fa-pen-to-square"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td>Heimilisfang</td>
                                            <td>{decrypt(posts[0].address) || null}</td>
                                            <td><button><i className="fa-light fa-pen-to-square"></i></button></td>
                                        </tr>
                                        <tr>
                                            <td>{t('newpassword')}</td>
                                            <td>**************************</td>
                                            <td><a href="/notandi/userpass"><i className="fa-light fa-pen-to-square"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div><div className="fiftycent">
                                <h3 className="section_heading"> Upplýsingar</h3>
                                <table className="user_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Læknir</td>
                                            <td>{posts[0].doctor}</td>
                                            <td><a href={"mailto:" + posts[0].doctor_email}><i className="fa-light fa-envelope"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td>Ofnæmisupplýsingar</td>
                                            <td>{decrypt(posts[0].allergies) || null}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Heilbrigðisstofnun</td>
                                            <td>{decrypt(posts[0].healthcare) || null}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Heimilislæknir</td>
                                            <td>{decrypt(posts[0].physician) || null}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/><br/>
                                <h3 className="section_heading"> {t('mymedicine')}</h3>
                                <table className="user_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Fyrirbyggjandi meðferð</td>
                                            <td><MedicineWeeks/></td>
                                        </tr>
                                        <tr>
                                            <td>Meðferð við köstum</td>
                                            <td><TreatmentOfSeizures/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </main>
                </div>
            )
        }else {
            return (
                <main></main>
            )
        }
    }
}

export default withTranslation()(User);