import React, { Component } from 'react'
import { withTranslation} from 'react-i18next';
import axios from 'axios';
import { getToken } from '../../Utils/Common';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();

export class Urgencies extends Component {

    state = { posts: [], isLoaded: false, Number: 5 }
    componentDidMount () {
        axios.get(global.config.api.url.live + '/urgency/5', {
            headers: { 
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded, Number} = this.state;
        if (isLoaded) {
            if (posts.length > 0) {
                return (
                    <div className="urgencies list">
                        <h3><Link to="/notandi/HAExpert">{t('home.acuteattacks', { Number })}</Link> <Link to="/notandi/HAExpert" className="fr"><i className="fa-solid fa-square-plus"></i> <div className='btn_text'> {t('inflammatory')}</div></Link></h3>
                        {posts.map(post =>
                            <div className="bradarkast" key={post.id}>
                                <div className={"dagur litur"+post.hex}>
                                    <strong>{moment(post.registered).format('Do')}</strong>
                                    {moment(post.registered).format('MMM')}
                                </div><div className="lysing">
                                    <h3><a href={"/notandi/haexpert/uppfaera/" + post.id }>{post.ended}</a></h3>
                                    <p><span>{t('advice')}:</span> {post.reaction}</p>
                                    <div className='e_status'>{athugaStodu(post.status, post.id, t('open'), t('closed') )}</div>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }else {
                return (
                    <div className="urgencies list">Þú er ekki búinn að skrá bólgukast, <Link to="/notandi/HAExpert"><strong>{t('inflammatory')}</strong></Link></div>
                )
            }
        } else {
            return (
                <div className="urgencies">
                    <div className="urgency">{t('loading')}</div>
                </div>
            )
        }
    }
}

function athugaStodu(p,id,open,closed) {
    if (p === 0) {
        let thisIsMyCopy = "<a href='/notandi/haexpert/Loka/"+id+"'>"+ open +"</a>"
        return <div className='opid' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    } else {
        let thisIsMyCopy = "<span>"+ closed +"</span>";
        return <div className='lokad' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    }
}

export default withTranslation()(Urgencies);