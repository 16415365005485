import React, { useState } from 'react';
import axios from 'axios';

import { getToken } from '../../../Utils/Common';
const token = getToken();

function ChangePass(props) {
    const [loading, setLoading] = useState(false);
    const [nyttlykil, setNyttlykil] = useState("");
    const [nyttlykilaftur, setNyttlykilaftur] = useState("");
    const [message, setMessage] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#4285F4");
    const [passok, setPassok] = useState(0);

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$%&])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    let handleCHanges = async (e) => {
        setNyttlykil(e);
        if(strongRegex.test(e)) {
            setBackgroundColor("#0F9D58");
            setPassok(2);
            /* if (nyttlykil === nyttlykilaftur) {
                setLoading(false);
            } */
        } else if(mediumRegex.test(e)) {
            setBackgroundColor("#F4B400");
            setPassok(1);
            //setLoading(true);
        } else {
            setBackgroundColor("#DB4437");
            setPassok(0);
            //setLoading(true);
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (nyttlykil.length < 3) {
            setMessage("Lykilorð verður að vera minnst 8 stafir á lengd!");
        } else {

            if (nyttlykil === nyttlykilaftur) {
                try {
                    var data = {
                        'password': nyttlykil
                    }
                    const headers = {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                    axios.post(global.config.api.url.live + '/newpass',data, { headers: headers }, { crossdomain: true }).then(response => {
                        setLoading(false);
                        console.log(response.data.data);
                        setMessage("Þetta gekk allt eins og í sögu!");
                    }).catch(error => {
                        setLoading(false);
                        setMessage("Ekki tókst að búa til notanda!");
                    });
                } catch (err) {
                    console.log(err);
                }

            } else {
                setMessage("Lykilorðin passa ekki!");
            }
        }
    }

    return (
        <div className="breytalykilordi">
            <form onSubmit={handleSubmit}>
            <div className="checkPasswordstrength">
                <input type="password" value={nyttlykil} placeholder="Nýtt lykilorð" onChange={(e) => handleCHanges(e.target.value)}  />
                <div className="passcheck"><div className={"errorcolor stig" + passok} style={{ backgroundColor: backgroundColor }}></div></div>
            </div>
            <input type="password" value={nyttlykilaftur} placeholder="Staðfesta lykilorð" onChange={(e) => setNyttlykilaftur(e.target.value)} />
            <button className={loading ? 'newpass_btn disabled' : 'newpass_btn'} disabled={loading}>Breyta</button>
            {message}
            </form>
        </div>
    )
}

export default ChangePass;