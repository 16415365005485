import React, { useState } from 'react';
import { getPhonenum, removeOTPCode, setUserSession } from '../../Utils/Common';
import axios from 'axios';

let phonenum = getPhonenum();

function InnskraFraSMS(props) {

    const [loading, setLoading] = useState(false);
    const Num = useFormInput('');
    const farsimanumerSMS = useFormInput(phonenum);
    const [error, setError] = useState(null);


    const handleLogin = () => {
        setError(null);
        setLoading(true);

        var data = {
            'phonenum': farsimanumerSMS.value,
            'optcode': Num.value
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post(global.config.api.url.live + '/checkotp',data, { headers: headers }, { crossdomain: true }).then(response => {
            setLoading(false);
            if (response.data.data.access_token) {
                setUserSession(response.data.data.access_token, response.data.data.refresh_token , "", response.data.data.session_id, response.data.data.fullname, response.data.data.kyn);
                removeOTPCode();
                props.history.push('/');
            } else {
                setError("Error in login");
            }
        }).catch(error => {
            setLoading(false);
            setError("Innskráning tókst ekki!");
        });

    }
    
    if (phonenum) {
        return (
            <main>
                <div className="innskraningssida">
                    <h3 className="login">Innskráning</h3>
                    <h2 className="login">Sjúklingar</h2>
                    <p className="login">Veldu hvað þér finnst best.</p>
                    <div className='tabcontent active'>
                        <h3>Sláðu inn kóða úr SMS til að auðkenna þig</h3>
                        <input type="text" {...farsimanumerSMS} placeholder="sláðu inn símanúmerið þitt" disabled="true" />
                        <div style={{ marginTop: 10 }}>
                            <input className="SMSNum f" type="text" {...Num} placeholder="6 stafa kóði" />
                        </div>
                        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                        <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLogin} disabled={loading} /><br /></div>
                    </div>
                </div>
            </main>
        );
    } else {
        return (
            <main>Er að ná {phonenum}</main>
        );
    }
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default InnskraFraSMS;