import React, { Component } from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { getToken } from '../../../../Utils/Common';
const token = getToken();

export class GetUnread extends Component {

    state = {
        unread: 0,
        isLoaded: false
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/messages/unread', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ unread: res.data.data.rows_returned, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { unread, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div className="unread">
                    <NavLink to="/notandi/samskipti" activeClassName="selected"><i className="fa-regular fa-inbox"></i><span data-count={unread}>{unread}</span></NavLink>
                </div>
            )
        } else {
            return (
                <div className="unread">
                    <NavLink to="/notandi/samskipti" activeClassName="selected"><i className="fa-regular fa-inbox"></i> <span data-count="0">0</span></NavLink>
                </div>
            )
        }
    }
}

export default GetUnread;