import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import { getToken } from '../../../../Utils/Common';
const token = getToken();

export class UserUrgencies extends Component {

    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {
        var config = {
            'Content-Type': 'application/json',
            'Authorization': token
        }

        axios.get(global.config.api.url.live + '/urgencies/'+this.props.userid + '/7', {
            param: {
                userid: this.props.userid
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (posts.mantone || posts.manttwo || posts.manthree || posts.mantfour || posts.manfive || posts.mansix) {
                return (
                    <div>
                        <h3><Link to="/notandi/skyrsla">{t('acase')} - {t('lastsixmonths')}</Link></h3>
                        <div className='UserGrafDash'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <div className={'GratItem h_' + posts.mansix}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.mansix}</strong></div>
                            </div><div className={'GratItem h_' + posts.manfive}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.manfive}</strong></div>
                            </div><div className={'GratItem h_' + posts.mantfour}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.mantfour}</strong></div>
                            </div><div className={'GratItem h_' + posts.manthree}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.manthree}</strong></div>
                            </div><div className={'GratItem h_' + posts.manttwo}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.manttwo}</strong></div>
                            </div><div className={'GratItem h_' + posts.mantone}>
                                <div className='grafback'></div>
                                <div className='grafused'><strong>{posts.mantone}</strong></div>
                            </div>
                        </div>
                        <div
                            overuleg_six={posts.overuleg_six}
                            vaeg_six={posts.vaeg_six}
                            midlungs_six={posts.midlungs_six}
                            alvarlegt_six={posts.alvarlegt_six}
                            mansix={posts.mansix}
                            overuleg_five={posts.overuleg_five}
                            vaeg_five={posts.vaeg_five}
                            midlungs_five={posts.midlungs_five}
                            alvarlegt_five={posts.alvarlegt_five}
                            manfive={posts.manfive}
                            overuleg_four={posts.overuleg_four}
                            vaeg_four={posts.vaeg_four}
                            midlungs_four={posts.midlungs_four}
                            alvarlegt_four={posts.alvarlegt_four}
                            mantfour={posts.mantfour}
                            overuleg_three={posts.overuleg_three}
                            vaeg_three={posts.vaeg_three}
                            midlungs_three={posts.midlungs_three}
                            alvarlegt_three={posts.alvarlegt_three}
                            manthree={posts.manthree}
                            overuleg_two={posts.overuleg_two}
                            vaeg_two={posts.vaeg_two}
                            midlungs_two={posts.midlungs_two}
                            alvarlegt_two={posts.alvarlegt_two}
                            manttwo={posts.manttwo}
                            overuleg={posts.overuleg}
                            vaeg={posts.vaeg}
                            midlungs={posts.midlungs}
                            alvarlegt={posts.alvarlegt}
                            mantone={posts.mantone}
                        />
                    </div>
                )
            } else {
                return (
                    <div></div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(UserUrgencies);