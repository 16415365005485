import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../../../Utils/Common';
import equal from 'fast-deep-equal'
import './Chart.css';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class UserUrgencies extends Component {

    state = {
        posts: [],
        isLoaded: false,
        perpage: 0,
        count: this.props.count,
        ollkost: 0
    }

    componentDidMount () {
        var config = {
            'Content-Type': 'application/json',
            'Authorization': token
        }

        axios.get(global.config.api.url.live + '/urgenciesnew/'+this.props.count + '/0', {
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data, isLoaded: true, perpage: res.data.data.per_page, ollkost: res.data.data.ollkost }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    componentDidUpdate() {
        const { count} = this.state;
        if(!equal(this.props.count, count)) {
            this.setState({ count: this.props.count })

            var config = { 'Content-Type': 'application/json', 'Authorization': token }
            axios.get(global.config.api.url.live + '/urgenciesnew/'+this.props.count + '/0', {
                headers: config
            })
            .then(res => this.setState({ posts: res.data.data, isLoaded: true, perpage: res.data.data.per_page, ollkost: res.data.data.ollkost }))
            .catch(error => {
                const response = error.response;
                if (response.status === 403) {
                    const json = JSON.stringify({ refresh_token: refreshtoken });
                    axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }, { crossdomain: true })
                    .then(response => {
                        setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                        window.location.reload();
                    })
                    .catch(error => {
                        const response = error.response;
                        console.log(response);
                    })
                } else {
                    removeUserSession();
                    window.location.reload();
                }
            })
        }
    }

    render() {
        const { posts, isLoaded, count, ollkost} = this.state;
        if (isLoaded) {
            let prealvarlegt = 0;
            let premidlungs = 0;
            let preoveruleg = 0;
            let prevaeg = 0;
            let ollkostin = "";
            if (ollkost === 1) {
                ollkostin = ollkost + " kast";
            } else {
                ollkostin = ollkost + " köst";
            }
            return (
                <div>
                    <link rel="stylesheet" href="/styles/charts.min.css" />
                    <div className="sixtycent">
                        <p><strong>Tilfelli</strong> - {ollkostin} síðustu {count} mánuði </p>
                        <div className="kost_alvarlegt litur_0"><svg className="ActivityRings" viewBox="0 0 37 37"><g className="ring ring1"><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background"></circle><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={Math.round(posts.alvarlegt/ollkost*100)+",100"}></circle></g></svg><div className="heild">Alvarleg bólga  <div className='kost_num'>{posts.alvarlegt}</div><strong>{Math.round(posts.alvarlegt/ollkost*100)+"%"}</strong></div></div>
                        <div className="kost_alvarlegt litur_1"><svg className="ActivityRings" viewBox="0 0 37 37"><g className="ring ring1"><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background"></circle><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={Math.round(posts.overuleg/ollkost*100)+",100"}></circle></g></svg><div className="heild">Óveruleg óþægindi  <div className='kost_num'>{posts.overuleg}</div><strong>{Math.round(posts.overuleg/ollkost*100)+"%"}</strong></div></div>
                        <div className="kost_alvarlegt litur_2"><svg className="ActivityRings" viewBox="0 0 37 37"><g className="ring ring1"><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background"></circle><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={Math.round(posts.midlungs/ollkost*100)+",100"}></circle></g></svg><div className="heild">Miðlungs bólga  <div className='kost_num'>{posts.midlungs}</div><strong>{Math.round(posts.midlungs/ollkost*100)+"%"}</strong></div></div>
                        <div className="kost_alvarlegt litur_3"><svg className="ActivityRings" viewBox="0 0 37 37"><g className="ring ring1"><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background"></circle><circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={Math.round(posts.vaeg/ollkost*100)+",100"}></circle></g></svg><div className="heild">Væg bólga  <div className='kost_num'>{posts.vaeg}</div><strong>{Math.round(posts.vaeg/ollkost*100)+"%"}</strong></div></div>

                        <div>
                            <table className="charts-css line multiple hide-data show-labels">
                                <tbody>
                                {posts.tasks.map(post =>
                                    <tr key={post.id}>
                                        <th scope="row"><span>{post.monthname} </span><br/> {post.year}<br/><span className='num' style={{"top": post.kost*30+15 - (post.kost*30+15)*2 }}>{post.kost}</span></th>
                                        <td style={{"--size": prealvarlegt}}><span className="data"> 23° </span></td>
                                        <td style={{"--start": "0."+prealvarlegt,"--end": "0." + post.alvarlegt}}><span className="data"> {prealvarlegt = post.alvarlegt} </span></td>
                                        <td style={{"--start": "0."+preoveruleg,"--end": "0." + post.overuleg}}><span className="data"> {preoveruleg = post.overuleg} </span></td>
                                        <td style={{"--start": "0."+premidlungs,"--end": "0." + post.midlungs}}><span className="data">  {premidlungs = post.midlungs} </span></td>
                                        <td style={{"--start": "0."+prevaeg,"--end": "0." + post.vaeg}}><span className="data"> {prevaeg = post.vaeg} </span></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="fortycent">
                        <p><strong>Staðsetningar</strong> - Síðustu {count} mánuði </p>
                        <div className="merking">
                            <div className={"likami " + getLikaman("1")}>
                                <div className="merki hofud val_99">{posts.face}<p className="tip">Andlit og varir</p></div>
                                <div className="merki munnhol val_99">{posts.munnhol}<p className="tip">Tunga og háls</p></div>
                                <div className="merki ondunarfaeri val_99">{posts.ondun}<p className="tip">Öndunarfæri</p></div>
                                <div className="merki meltingarfaeri val_99">{posts.kvidur}<p className="tip">Meltingarfæri</p></div>
                                <div className="merki kynfaeri val_99">{posts.kynfaeri}<p className="tip">Kynfæri</p></div>
                                <div className="merki vinstrihond val_99">{posts.lh}<p className="tip">Vinstri Lófi</p></div>
                                <div className="merki haegrihond val_99">{posts.rh}<p className="tip">Hægri Lófi</p></div>
                                <div className="merki vinstrio val_99">{posts.ov}<p className="tip">Vinstri hönd</p></div>
                                <div className="merki haegrio val_99">{posts.oh}<p className="tip">Hægri hönd</p></div>
                                <div className="merki hne vinstri val_99">{posts.vhne}<p className="tip">Vinstri Hné</p></div>
                                <div className="merki hne val_99">{posts.hne}<p className="tip">Hægri Hné</p></div>
                                <div className="merki vinstrifotur val_99">{posts.ll}<p className="tip">Vinstri fótur</p></div>
                                <div className="merki haegrifotur val_99">{posts.rl}<p className="tip">Hægri fótur</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getLikaman(kynid) {

    if (kynid === "1") {
        return "kk";
    } else if (kynid === "2") {
        return "kvk";
    } else if (kynid === "3") {
        return "annad";
    }
}

export default withTranslation()(UserUrgencies);