import React, { useState } from 'react';

function ADHD(props) {
    const [nyttlykil, setNyttlykil] = useState("");
    const [nyttlykilaftur, setNyttlykilaftur] = useState("");
    const [message, setMessage] = useState("");
    const query = new URLSearchParams(props.location.search);
    const key = query.get('key');
    const email = query.get('email');


    let handleSubmit = async (e) => {
        e.preventDefault();

        if (nyttlykil.length < 8) {
            setMessage("Lykilorð verður að vera minnst 8 stafir á lengd!");
        } else {

            if (nyttlykil === nyttlykilaftur) {
                try {
                    let res = await fetch(global.config.api.url.live + "/changepass", {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        crossdomain: true,
                        body: JSON.stringify({
                            key: key,
                            email: email,
                            password: nyttlykil,
                        }),
                    });

                    let resJson = await res.json();
                    console.log(resJson);
                    if (res.status === 201) {

                        setNyttlykil("");
                        setNyttlykilaftur("");
                        setMessage("Þetta gekk allt eins og í sögu!");
                    } else {
                        setMessage("Ekki tókst breyta!");
                    }
                } catch (err) {
                    console.log(err);
                }

            } else {
                setMessage("Lykilorðin passa ekki!");
            }
        }
    }

    return (
        <main>
            <div className="wrapper zztop">
                <div className="breytalykilordi">
                    <h3>Endurstilla lykilorð</h3>
                    <form onSubmit={handleSubmit}>
                    <input type="password" value={nyttlykil} placeholder="Nýtt lykilorð" onChange={(e) => setNyttlykil(e.target.value)} />
                    <input type="text" value={nyttlykilaftur} placeholder="Staðfesta lykilorð" onChange={(e) => setNyttlykilaftur(e.target.value)} />
                    <button className="endurstillabtn">Endurstilla lykilorð</button>
                    {message}
                    </form>
                </div>
            </div>
        </main>
    )
}

export default ADHD;