import React, { Component } from 'react'
import axios from 'axios';
import SystemHeader from './SystemHeader';

import moment from "moment";
import 'moment/locale/is';
import { decrypt } from '../../helpers/decrypt'

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class QolView extends Component {

    state = { posts: [], isLoaded: false, perpage: 0, userid: this.props.match.params.id }
    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        var selectedUser = "";

        if(this.props.match.params.id !== undefined) {
            selectedUser = this.props.match.params.id;
        }

        axios.get(global.config.api.url.live + '/qolview/'+ selectedUser, {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            console.log(posts);
            return (
                <div>
                    <SystemHeader title="Lífsgæðalistinn"/>
                    <main>
                    {posts.map(post =>
                        <div className="valspurningar">
                        <p>{decrypt(post.name)}<br/>Heildarskor <strong className={getColorUp(post.score)}>{post.score}%</strong><br/>Próf var tekið þann {moment(post.createdon).format('Do MMM YYYY')}</p>
                        <div className="lifsgaeda_flokkur">
                            <h2 className={"total_" + post.verkirtotal}>Verkir og bólga</h2>
                            <h3 className="questions">Verkir í stoðkerfi</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={post.q1 === 0} id="verkir_stodkerfi_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={post.q1 === 1} id="verkir_stodkerfi_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={post.q1 === 2} id="verkir_stodkerfi_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={post.q1 === 3} id="verkir_stodkerfi_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={post.q1 === 4} id="verkir_stodkerfi_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
                                </label>
                            </div>

                            <h3 className="questions">Kviðverkir</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="kvidverkir" checked={post.q2 === 0} id="kvidverkir_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="kvidverkir_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={post.q2 === 1} id="kvidverkir_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="kvidverkir_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={post.q2 === 2} id="kvidverkir_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="kvidverkir_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={post.q2 === 3} id="kvidverkir_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="kvidverkir_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={post.q2 === 4} id="kvidverkir_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="kvidverkir_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
                                </label>
                            </div>
                            <h3 className="questions">Höfuðverkur</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="hofudverkir" checked={post.q3 === 0} id="hofudverkir_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="hofudverkir_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={post.q3 === 1} id="hofudverkir_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="hofudverkir_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={post.q3 === 2} id="hofudverkir_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="hofudverkir_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={post.q3 === 3} id="hofudverkir_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="hofudverkir_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
                                </label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={post.q3 === 4} id="hofudverkir_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="hofudverkir_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
                                </label>
                            </div>
                        </div>

                        <div className="lifsgaeda_flokkur">
                            <h2 className={"cat2 total_" + post.virknitotal}>Líkamleg virkni</h2>
                            <h3 className="questions">Þreyta (orkuleysi, magnleysi)</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="threyta" checked={post.q4 === 0} id="likamleg_threyta_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_threyta_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Engin<br/> þreyta</div>
                                </label><input className="checkbox-tools" type="radio" name="threyta" checked={post.q4 === 1} id="likamleg_threyta_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_threyta_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Væg<br/> þreyta</div>
                                </label><input className="checkbox-tools" type="radio" name="threyta" checked={post.q4 === 2} id="likamleg_threyta_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_threyta_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> þreyta</div>
                                </label><input className="checkbox-tools" type="radio" name="threyta" checked={post.q4 === 3} id="likamleg_threyta_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_threyta_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Mikil<br/> þreyta</div>
                                </label><input className="checkbox-tools" type="radio" name="threyta" checked={post.q4 === 4} id="likamleg_threyta_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_threyta_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> þreyta</div>
                                </label>
                            </div>
                            
                            <h3 className="questions">Mæði</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="maedi" checked={post.q5 === 0} id="likamleg_maedi_0" value="0"/>
                                <label className="for-checkbox-tools" htmlFor="likamleg_maedi_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Engin<br/> mæði</div>
                                </label><input className="checkbox-tools" type="radio" name="maedi" checked={post.q5 === 1} id="likamleg_maedi_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_maedi_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Væg<br/> mæði</div>
                                </label><input className="checkbox-tools" type="radio" name="maedi" checked={post.q5 === 2} id="likamleg_maedi_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_maedi_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> mæði</div>
                                </label><input className="checkbox-tools" type="radio" name="maedi" checked={post.q5 === 3} id="likamleg_maedi_3" value="3"/>
                                <label className="for-checkbox-tools" htmlFor="likamleg_maedi_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Mikil<br/> mæði</div>
                                </label><input className="checkbox-tools" type="radio" name="maedi" checked={post.q5 === 4} id="likamleg_maedi_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_maedi_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> mæði</div>
                                </label>
                            </div>
                            
                            <h3 className="questions">Almenn færni</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="geta" checked={post.q6 === 0} id="likamleg_geta_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_geta_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Full<br/> geta</div>
                                </label><input className="checkbox-tools" type="radio" name="geta" checked={post.q6 === 1} id="likamleg_geta_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_geta_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Mikil<br/> geta</div>
                                </label><input className="checkbox-tools" type="radio" name="geta" checked={post.q6 === 2} id="likamleg_geta_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_geta_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> geta</div>
                                </label><input className="checkbox-tools" type="radio" name="geta" checked={post.q6 === 3} id="likamleg_geta_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_geta_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Væg<br/> geta</div>
                                </label><input className="checkbox-tools" type="radio" name="geta" checked={post.q6 === 4} id="likamleg_geta_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="likamleg_geta_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Engin<br/> geta</div>
                                </label>
                            </div>
                        </div>

                        <div className="lifsgaeda_flokkur">
                            <h2 className={"cat3 total_" + post.andlegtotal}>Andleg líðan</h2>
                            <h3 className="questions">Kvíði</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="kvidi" checked={post.q7 === 0} id="andleg_kvidi_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="andleg_kvidi_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Enginn<br/> kvíði</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidi" checked={post.q7 === 1} id="andleg_kvidi_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="andleg_kvidi_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Vægur<br/> kvíði</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidi" checked={post.q7 === 2} id="andleg_kvidi_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="andleg_kvidi_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> kvíði</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidi" checked={post.q7 === 3} id="andleg_kvidi_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="andleg_kvidi_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Mikill<br/> kvíði</div>
                                </label><input className="checkbox-tools" type="radio" name="kvidi" checked={post.q7 === 4} id="andleg_kvidi_4" value="4"  />
                                <label className="for-checkbox-tools" htmlFor="andleg_kvidi_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versti mögulegi<br/> kvíði</div>
                                </label>
                            </div>
                            <h3 className="questions">Þunglyndi</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="thunglyndi" checked={post.q8 === 0} id="andleg_thunglyndi_0" value="0"  />
                                <label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Ekkert<br/> þunglyndi</div>
                                </label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={post.q8 === 1} id="andleg_thunglyndi_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Vægt<br/> þunglyndi</div>
                                </label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={post.q8 === 2} id="andleg_thunglyndi_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkurt<br/> þunglyndi</div>
                                </label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={post.q8 === 3} id="andleg_thunglyndi_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Mikið<br/> þunglyndi</div>
                                </label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={post.q8 === 4} id="andleg_thunglyndi_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> þunglyndi</div>
                                </label>
                            </div>
                        </div>

                        <div className="lifsgaeda_flokkur">
                            <h2 className={"cat4 total_" + post.almenntotal}>Almenn líðan</h2>

                            <h3 className="questions">Almenn líðan </h3>

                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="almennlidan" checked={post.q9 === 0} id="almenn_lidan_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="almenn_lidan_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Góð<br/> líðan</div>
                                </label><input className="checkbox-tools" type="radio" name="almennlidan" checked={post.q9 === 1} id="almenn_lidan_1" value="1"  />
                                <label className="for-checkbox-tools" htmlFor="almenn_lidan_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Ágæt<br/> líðan</div>
                                </label><input className="checkbox-tools" type="radio" name="almennlidan" checked={post.q9 === 2} id="almenn_lidan_2" value="2" />
                                <label className="for-checkbox-tools" htmlFor="almenn_lidan_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Sæmileg<br/> líðan</div>
                                </label><input className="checkbox-tools" type="radio" name="almennlidan" checked={post.q9 === 3} id="almenn_lidan_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="almenn_lidan_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Slæm<br/> líðan</div>
                                </label><input className="checkbox-tools" type="radio" name="almennlidan" checked={post.q9 === 4} id="almenn_lidan_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="almenn_lidan_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> líðan</div>
                                </label>
                            </div>

                            <h3 className="questions">Svefngæði</h3>
                            <div className="spurningar_checkboxes">
                                <input className="checkbox-tools" type="radio" name="svefngaedi" checked={post.q10 === 0} id="almenn_svefngaedi_0" value="0" />
                                <label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_0">
                                    <i className="fa-regular fa-face-smile"></i> <div>Góður<br/> svefn</div>
                                </label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={post.q10 === 1} id="almenn_svefngaedi_1" value="1" />
                                <label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_1">
                                    <i className="fa-regular fa-face-expressionless"></i> <div>Ágætur<br/> svefn</div>
                                </label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={post.q10 === 2} id="almenn_svefngaedi_2" value="2"  />
                                <label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_2">
                                    <i className="fa-regular fa-face-frown-slight"></i> <div>Sæmilegur<br/> svefn</div>
                                </label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={post.q10 === 3} id="almenn_svefngaedi_3" value="3" />
                                <label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_3">
                                    <i className="fa-regular fa-face-anguished"></i> <div>Slæmur<br/> svefn</div>
                                </label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={post.q10 === 4} id="almenn_svefngaedi_4" value="4" />
                                <label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_4">
                                    <i className="fa-regular fa-face-confounded"></i> <div>Versti mögulegi<br/> svefn</div>
                                </label>
                            </div>
                        </div>
                        </div>
                        )}
                    </main>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}
/*
function getColor(p) {
    let mycolor = "litur_4";
    
    if (p === 0) {
        mycolor = "litur_0";
    } else if (p > 0 && p <= 24) {
        mycolor = "litur_1";
    } else if (p >= 25 && p <= 49) {
        mycolor = "litur_2";
    } else if (p >= 50 && p <= 74) {
        mycolor = "litur_3";
    } else if (p >= 75) {
        mycolor = "litur_4";
    }

    return mycolor;
}
*/
function getColorUp(p) {
    let mycolor = "litur_0";

    if (p <= 25 && p >= 0) {
        mycolor = "litur_4";
    } else if (p > 25 && p <= 50) {
        mycolor = "litur_3";
    } else if (p > 50 && p <= 75) {
        mycolor = "litur_2";
    } else if (p > 75 && p <= 99) {
        mycolor = "litur_1";
    } else if (p >= 100) {
        mycolor = "litur_0";
    }

    return mycolor;
}
/*
function getVerkir(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Engir";
    } else if (p === 1) {
        verkir = "Vægir";
    } else if (p === 2) {
        verkir = "Þónokkrir";
    } else if (p === 3) {
        verkir = "Miklir";
    } else if (p === 4) {
        verkir = "Verstu mögulegu";
    }
    return verkir;
}

function getVerkur(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Enginn";
    } else if (p === 1) {
        verkir = "Vægur";
    } else if (p === 2) {
        verkir = "Þónokkrur";
    } else if (p === 3) {
        verkir = "Mikill";
    } else if (p === 4) {
        verkir = "Versti mögulegi";
    }
    return verkir;
}

function getThreyta(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Engin";
    } else if (p === 1) {
        verkir = "Væg";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Mikil";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getGeta(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Full";
    } else if (p === 1) {
        verkir = "Mikil";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Væg";
    } else if (p === 4) {
        verkir = "Engin";
    }
    return verkir;
}

function getLidan(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Góð";
    } else if (p === 1) {
        verkir = "Ágæt";
    } else if (p === 2) {
        verkir = "Sæmileg";
    } else if (p === 3) {
        verkir = "Slæm";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getSvefn(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Góður";
    } else if (p === 1) {
        verkir = "Ágætur";
    } else if (p === 2) {
        verkir = "Sæmilegur";
    } else if (p === 3) {
        verkir = "Slæmur";
    } else if (p === 4) {
        verkir = "Versti mögulegi";
    }
    return verkir;
}

function getThunglyndi(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Ekkert";
    } else if (p === 1) {
        verkir = "Vægt";
    } else if (p === 2) {
        verkir = "Þónokkurt";
    } else if (p === 3) {
        verkir = "Mikið";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getKvidi(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Enginn";
    } else if (p === 1) {
        verkir = "Vægur";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Mikill";
    } else if (p === 4) {
        verkir = "Versti mögulegi";
    }
    return verkir;
}

function getHeildarskor(heildarskor) {
    let heildarskorIcon = "";
    if (heildarskor <= 25 && heildarskor > 0) {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (heildarskor > 25 && heildarskor <= 50) {
        heildarskorIcon = "fa-regular fa-face-anguished";
    } else if (heildarskor > 50 && heildarskor <= 75) {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else if (heildarskor > 75 && heildarskor <= 99) {
        heildarskorIcon = "fa-regular fa-face-expressionless";
    } else if (heildarskor >= 100) {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}
*/
export default QolView;