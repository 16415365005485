import React, { useState } from 'react';
import { setUserSession } from '../../Utils/Common';
import axios from 'axios';
import { encrypt } from '../helpers/decrypt'

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [tab1active, setTab1active] = useState(true);
    const [tab2active, setTab2active] = useState(false);
    const [tab3active, setTab3active] = useState(false);
    const [myotpcode, setOtpcode] = useState('');
    const [myoryggistalan, setOryggistalan] = useState('');
    const [mobileInput, setMobileInput] = useState(false);

    const [rafinn, setRafinn] = useState(false)
    const [errorinraf, setErrorinraf] = useState(false)

    const username = useFormInput('');
    const password = useFormInput('');
    const farsimanumerSMS = useFormInput('');
    const SMSCode = useFormInput('');
    const [SMSCodeInput, setSMSCodeInput] = useState(false);
    const farsimanumer = useFormInput('');
    const [error, setError] = useState(null);

    const handleLoginRaf = () => {
        var val = Math.floor(1000 + Math.random() * 9000);
        var data = '';
        setErrorinraf(false);
        const headers = {
            'Cookie': 'audssossolb=03', 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'
        }

        axios.post('https://pg9ye.audkenni.is/sso/json/realms/root/realms/audkenni/authenticate?authIndexType=service&authIndexValue=api_v201',data, { headers: headers }, { crossdomain: true }).then(response => {

            var datasepttwo = JSON.stringify({
                "authId": response.data.authId,
                "callbacks": [
                    {
                        "type": "NameCallback",
                        "output": [
                        {
                            "name": "prompt",
                            "value": "Sláðu inn clientId"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken1",
                            "value": "nianApi"
                        }
                    ],
                    "_id": 0
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Sláðu inn Related Party"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken2",
                            "value": "MyOwnClient"
                        }
                    ],
                    "_id": 1
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Sláðu inn símanúmer eða kennitölu"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken3",
                            "value": farsimanumer.value
                        }
                    ],
                    "_id": 2
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Sláðu inn skilaboð til notanda"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken4",
                            "value": "Innskráning í frumu - Code: " + val
                        }
                    ],
                    "_id": 3
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Nota vchoice (true eða false)"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken5",
                            "value": "false"
                        }
                    ],
                    "_id": 4
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Nota confirmMessage (true eða false)"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken6",
                            "value": "false"
                        }
                    ],
                    "_id": 5
                },{
                    "type": "NameCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Sláðu inn Hash gildi"
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken7",
                            "value": ""
                        }
                    ],
                    "_id": 6
                },{
                    "type": "ChoiceCallback",
                    "output": [
                        {
                            "name": "prompt",
                            "value": "Veldu auðkenningarleið"
                        },
                        {
                            "name": "choices",
                            "value": [
                                "sim",
                                "card",
                                "app",
                                "cardnew",
                                "cardold"
                            ]
                        },
                        {
                            "name": "defaultChoice",
                            "value": 0
                        }
                    ],
                    "input": [
                        {
                            "name": "IDToken8",
                            "value": 0
                        }
                    ],
                    "_id": 7
                }
                ]
            });

            const headerssepttwo = {
                'Accept-API-Version': 'resource=2.0,protocol=1.0',
                'Cookie': 'audssossolb=03; audsso=UgT8UelNnFKc-Wm0GvQzDpwu0Ag.*AAJTSQACMDIAAlNLABwxQ1M5QVVlTFFxaXVCZWFTMkxXajhHV2JMWTg9AAR0eXBlAANDVFMAAlMxAAIwMw..*; audssossolb=03',
                'Content-Type': 'application/json'
            }

            axios.post('https://pg9ye.audkenni.is/sso/json/realms/root/realms/audkenni/authenticate?authIndexType=service&authIndexValue=api_v201',datasepttwo, { headers: headerssepttwo }, { crossdomain: true }).then(responsesepttwo => {
                var tokenId = "";
                setTimeout(function() {
                    var dataseptthrir = JSON.stringify({
                        "authId": responsesepttwo.data.authId,
                        "callbacks": [
                            {
                                "type": "PollingWaitCallback",
                                "output": [
                                    {
                                        "name": "waitTime",
                                        "value": "5000"
                                    },{
                                        "name": "message",
                                        "value": "templates.user.LoginTemplate.pollingwaitmessage"
                                    }
                                ]
                            }
                        ]
                    });
                    const headersseptthrir = {
                        'Accept-API-Version': 'resource=2.0,protocol=1.0',
                        'Cookie': 'audssossolb=03; audsso=UgT8UelNnFKc-Wm0GvQzDpwu0Ag.*AAJTSQACMDIAAlNLABwxQ1M5QVVlTFFxaXVCZWFTMkxXajhHV2JMWTg9AAR0eXBlAANDVFMAAlMxAAIwMw..*; audssossolb=03',
                        'Content-Type': 'application/json'
                    }

                    axios.post('https://pg9ye.audkenni.is/sso/json/realms/root/realms/audkenni/authenticate',dataseptthrir, { headers: headersseptthrir }, { crossdomain: true }).then(responseseptthrir => {
                        if (responseseptthrir.data.tokenId !== undefined) {
                            tokenId = responseseptthrir.data.tokenId;
                            var data = { 'phonenum': encrypt(farsimanumer.value)}
                            const headers = {'Content-Type': 'application/json'}
                            axios.post(global.config.api.url.live + '/sessionsrs', data, { headers: headers }, { crossdomain: true }).then(response => {
                                setLoading(false);
                                if (response.data.data.access_token) {
                                    setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu);
                                    props.history.push('/');
                                } else {
                                    setRafinn(false);
                                    setLoading(false);
                                    setError("Innskráning tókst ekki!");
                                }
                            }).catch(error => {
                                setRafinn(false);
                                setLoading(false);
                                setError("Innskráning tókst ekki!");
                            });

                            /*
                            const headersseptfjorir = { 'Cookie': 'audsso='+tokenId, 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' }
                            axios.get('https://pg9ye.audkenni.is/sso/oauth2/realms/root/realms/audkenni/authorize?service=api_v201&client_id=nianApi&response_type=code&scope=openid profile signature&code_challenge=5WnuXW4ALVNtX9G6MydkrPs-F2suz0TQkoaKBsk8Hzk&code_challenge_method=S256&state=abc123&redirect_uri=http://localhost:3000/callback', { headers: headersseptfjorir }, { crossdomain: true }).then(responseseptfjorir => {
                                console.log(responseseptfjorir.data)
                            }).catch(error => {
                                console.log(error);
                            });
                            */
                        }
                    }).catch(error => {
                        setRafinn(false);
                        setLoading(false);
                        setError("Innskráning tókst ekki!");
                    });
                }, 30000);

                setTimeout(function() {
                    var dataseptthrir = JSON.stringify({
                        "authId": responsesepttwo.data.authId,
                        "callbacks": [
                            {
                                "type": "PollingWaitCallback",
                                "output": [
                                    {
                                        "name": "waitTime",
                                        "value": "5000"
                                    },{
                                        "name": "message",
                                        "value": "templates.user.LoginTemplate.pollingwaitmessage"
                                    }
                                ]
                            }
                        ]
                    });
                    const headersseptthrir = {
                        'Accept-API-Version': 'resource=2.0,protocol=1.0',
                        'Cookie': 'audssossolb=03; audsso=UgT8UelNnFKc-Wm0GvQzDpwu0Ag.*AAJTSQACMDIAAlNLABwxQ1M5QVVlTFFxaXVCZWFTMkxXajhHV2JMWTg9AAR0eXBlAANDVFMAAlMxAAIwMw..*; audssossolb=03',
                        'Content-Type': 'application/json'
                    }
                    if (tokenId === "") {
                        axios.post('https://pg9ye.audkenni.is/sso/json/realms/root/realms/audkenni/authenticate',dataseptthrir, { headers: headersseptthrir }, { crossdomain: true }).then(responseseptthrir => {
                            if (responseseptthrir.data.tokenId !== undefined) {
                                tokenId = responseseptthrir.data.tokenId;
                                var data = { 'phonenum': encrypt(farsimanumer.value)}
                                const headers = {'Content-Type': 'application/json'}
                                axios.post(global.config.api.url.live + '/sessionsrs', data, { headers: headers }, { crossdomain: true }).then(response => {
                                    setLoading(false);
                                    if (response.data.data.access_token) {
                                        setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu);
                                        props.history.push('/');
                                    } else {
                                        setRafinn(false);
                                        setLoading(false);
                                        setError("Innskráning tókst ekki!");
                                    }
                                }).catch(error => {
                                    setRafinn(false);
                                    setLoading(false);
                                    setError("Innskráning tókst ekki!");
                                });

                                /*
                                const headersseptfjorir = { 'Cookie': 'audsso='+tokenId, 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' }
                                axios.get('https://pg9ye.audkenni.is/sso/oauth2/realms/root/realms/audkenni/authorize?service=api_v201&client_id=nianApi&response_type=code&scope=openid profile signature&code_challenge=5WnuXW4ALVNtX9G6MydkrPs-F2suz0TQkoaKBsk8Hzk&code_challenge_method=S256&state=abc123&redirect_uri=http://localhost:3000/callback', { headers: headersseptfjorir }, { crossdomain: true }).then(responseseptfjorir => {
                                    console.log(responseseptfjorir.data)
                                }).catch(error => {
                                    console.log(error);
                                });
                                */
                            }
                        }).catch(error => {
                            setRafinn(false);
                            setLoading(false);
                            setError("Innskráning tókst ekki!");
                        });
                    }
                }, 40000);

                setTimeout(function() {
                    var dataseptthrir = JSON.stringify({
                        "authId": responsesepttwo.data.authId,
                        "callbacks": [
                            {
                                "type": "PollingWaitCallback",
                                "output": [
                                    {
                                        "name": "waitTime",
                                        "value": "5000"
                                    },{
                                        "name": "message",
                                        "value": "templates.user.LoginTemplate.pollingwaitmessage"
                                    }
                                ]
                            }
                        ]
                    });
                    const headersseptthrir = {
                        'Accept-API-Version': 'resource=2.0,protocol=1.0',
                        'Cookie': 'audssossolb=03; audsso=UgT8UelNnFKc-Wm0GvQzDpwu0Ag.*AAJTSQACMDIAAlNLABwxQ1M5QVVlTFFxaXVCZWFTMkxXajhHV2JMWTg9AAR0eXBlAANDVFMAAlMxAAIwMw..*; audssossolb=03',
                        'Content-Type': 'application/json'
                    }
                    if (tokenId === "") {
                        axios.post('https://pg9ye.audkenni.is/sso/json/realms/root/realms/audkenni/authenticate',dataseptthrir, { headers: headersseptthrir }, { crossdomain: true }).then(responseseptthrir => {
                            if (responseseptthrir.data.tokenId !== undefined) {
                                tokenId = responseseptthrir.data.tokenId;
                                var data = { 'phonenum': encrypt(farsimanumer.value)}
                                const headers = {'Content-Type': 'application/json'}
                                axios.post(global.config.api.url.live + '/sessionsrs', data, { headers: headers }, { crossdomain: true }).then(response => {
                                    setLoading(false);
                                    if (response.data.data.access_token) {
                                        setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu);
                                        props.history.push('/');
                                    } else {
                                        setError("Error in login");
                                    }
                                }).catch(error => {
                                    setLoading(false);
                                    setError("Innskráning tókst ekki!");
                                });

                                /*
                                const headersseptfjorir = { 'Cookie': 'audsso='+tokenId, 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' }
                                axios.get('https://pg9ye.audkenni.is/sso/oauth2/realms/root/realms/audkenni/authorize?service=api_v201&client_id=nianApi&response_type=code&scope=openid profile signature&code_challenge=5WnuXW4ALVNtX9G6MydkrPs-F2suz0TQkoaKBsk8Hzk&code_challenge_method=S256&state=abc123&redirect_uri=http://localhost:3000/callback', { headers: headersseptfjorir }, { crossdomain: true }).then(responseseptfjorir => {
                                    console.log(responseseptfjorir.data)
                                }).catch(error => {
                                    console.log(error);
                                });
                                */
                            } else {
                                setRafinn(false);
                                setLoading(false);
                                setError("Innskráning tókst ekki!");
                            }
                        }).catch(error => {
                            setRafinn(false);
                            setLoading(false);
                            setError("Innskráning tókst ekki!");
                        });
                    }
                }, 50000);

            }).catch(error => {
                setRafinn(false);
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });

            setOryggistalan(val);
            setRafinn(true);
            setError(null);
            setLoading(true);
        }).catch(error => {
            setRafinn(false);
            setLoading(false);
            setError("Innskráning tókst ekki!");
        });
    }

    const handleLoginSMS = () => {
        setError(null);
        setLoading(true);

        if (myotpcode) {
            var data = {
                'code': encrypt(farsimanumerSMS.value),
                'phonenum': farsimanumerSMS.value,
                'optcode': SMSCode.value
            }

            const headers = { 'Content-Type': 'application/json' }

            axios.post(global.config.api.url.live + '/checkotp',data, { headers: headers }, { crossdomain: true }).then(response => {
                setLoading(false);
                if (response.data.data.access_token) {
                    setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu);
                props.history.push('/');
                } else {
                    setError("Error in login");
                }
            }).catch(error => {
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });

        } else {
            var dataPhone = {
                'code': encrypt(farsimanumerSMS.value),
                'phonenum': farsimanumerSMS.value,
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            axios.post(global.config.api.url.live + '/sendotp',dataPhone, { headers: headers }, { crossdomain: true }).then(response => {
                setLoading(false);
                if (response.data.data.code) {
                    setOtpcode(response.data.data.code);
                    setSMSCodeInput(true);
                    setMobileInput(true);
                } else {
                    setError("Error in login");
                }
            }).catch(error => {
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });
        }
    }

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        var data = {
            'username': encrypt(username.value),
            'password': password.value
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post(global.config.api.url.live + '/sessions', data, { headers: headers }, { crossdomain: true }).then(response => {
            setLoading(false);
            if (response.data.data.access_token) {
                setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu);
                props.history.push('/');
            } else {
                setError("Error in login");
            }
        }).catch(error => {
            setLoading(false);
            setError("Innskráning tókst ekki!");
        });

    }

    const showRafraen = () => {
        setTab1active(true);
        setTab2active(false);
        setTab3active(false);
    }

    const showSMS = () => {
        setTab1active(false);
        setTab2active(true);
        setTab3active(false);
    }

    const showKenni = () => {
        setTab1active(false);
        setTab2active(false);
        setTab3active(true);
    }

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Innskráning</h3>
                <h2 className="login">Sjúklingar</h2>
                <p className="login">Veldu hvað þér finnst best.</p>
                <ul className="loginmethod">
                    <li className={tab1active ? 'rafraen active' : 'rafraen'} onClick={showRafraen}>Rafræn skilríki í síma</li>
                    <li className={tab2active ? 'fasms active' : 'fasms'} onClick={showSMS}>Fá SMS</li>
                    <li className={tab3active ? 'kenni active' : 'kenni'} onClick={showKenni}>Innskráning</li>
                </ul>

                <div className={tab1active ? 'tabcontent active' : 'tabcontent'} id="tab1">
                    <div className={errorinraf ? 'advorun active' : 'advorun'} >Rann út á tíma / Notandi hætti við</div>
                    <div style={{ marginTop: 5 }} className={rafinn ? 'farsimanumer unactive' : 'farsimanumer'}>
                        <label htmlFor='farsimanumer'>Símanúmer</label>
                        <input type="text" {...farsimanumer} id="farsimanumer" pattern="[0-9]*" placeholder='000-0000' />
                    </div>
                    <div className={rafinn ? 'oryggistalan active' : 'oryggistalan'}>
                        Öryggistalan þín er:<br/>
                        <strong>{myoryggistalan}</strong>
                    </div>
                    <div className={rafinn ? 'oryggistalaninfo active' : 'oryggistalaninfo'}>
                        <p>Staðfestu auðkenninguna ef öryggistalan er sú sama og birtist á símanum þínum.</p>
                        <p>Ath. að öryggistalan er ekki PIN-númerið á skilríkjunum þínum.</p>
                    </div>
                    <div className={rafinn ? 'loader active' : 'loader'}><div className="custom-loader"></div></div>
                    <div style={{ marginTop: 15 }}><input type="button"  disabled={loading} className="loginbtn " value={loading ? 'Hleð...' : 'Auðkenna'} onClick={handleLoginRaf} /><br /></div>
                </div>

                <div className={tab2active ? 'tabcontent active' : 'tabcontent'} id="tab2">
                    <h3>Skráðu þig inn með SMS og fáðu lykilorð sent í símann.</h3>
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...farsimanumerSMS} placeholder="sláðu inn símanúmerið þitt" disabled={mobileInput} />
                    </div>
                    <div className={SMSCodeInput ? 'smscode active' : 'smscode'}>
                        <input type="text" {...SMSCode} placeholder="6 stafa kóði" />
                    </div>
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLoginSMS} disabled={loading} /><br /></div>
                </div>
                <div className={tab3active ? 'tabcontent active' : 'tabcontent'} id="tab3">
                    <h3>Sláðu inn kennitöluna þína og lykilorð sem þú hefur valið</h3>
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...username} placeholder="Kennitala" />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <input type="password" {...password} placeholder="Lykilorð" />
                    </div>
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLogin} disabled={loading} /><br /></div>
                    <div className="gleymt"><a href="/endurstilla-lykilord">Gleymt lykilorð!</a></div>
                    <div className="innskraning_onnur">Innskráning fyrir <a href="/innskra">Fagfólk</a></div>
                </div>
            </div>
        </main>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;
