import React, { useState } from 'react';

function Endurstillalykilord(props) {
    const [useremail, setUseremail] = useState("");
    const [message, setMessage] = useState("");

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail(useremail)) {
            setMessage("Þetta er ekki rétt netfang!");
        } else {

            try {
                let res = await fetch(global.config.api.url.live + "/passreset", {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    crossdomain: true,
                    body: JSON.stringify({
                        useremail: useremail
                    }),
                });

                let resJson = await res.json();
                console.log(resJson);
                if (res.status === 201) {
                    setUseremail("");
                    setMessage("Þú og Tom Hanks eigið eitt sameiginlegt núna: You've Got Mail!");
                } else {
                    setMessage("Villa kom upp!");
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <main>
            <div className="wrapper zztop">
                <div className="breytalykilordi">
                    <h3>Endurstilla lykilorð</h3>
                    <p>Týndirðu lykilorðinu þínu? Þú getur fengið það aftur hér.</p>
                    <p>Skrifaðu netfangið sem þú notar í tengslum við aðganginn þinn og þú munt fá tölvupóst með kóða sem mun leyfa þér að endurstilla lykilorðið.</p>
                    <form onSubmit={handleSubmit}>
                    <input type="text" maxLength="255" value={useremail} placeholder="Settu inn tölvupóstfang" onChange={(e) => setUseremail(e.target.value)} />
                    <button className="endurstillabtn">Endurstilla lykilorð</button>
                    {message}
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Endurstillalykilord;