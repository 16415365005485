import React from 'react'
import Sidebar from '../templates/SidebarAdmin';

const AdminSystem = (props) => (
    <div>
        <Sidebar/>
        <div className='admin-system full'>
            {props.children}
        </div>
    </div>
)

export default AdminSystem