import React, { Component } from 'react'
import axios from 'axios';
import SystemHeader from './SystemHeader';

import moment from "moment";
import 'moment/locale/is';

import { decrypt } from '../../helpers/decrypt'

import { getToken } from  '../../../Utils/admincommon'
const token = getToken();

export class ViewUrgency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: new Date(),
            id: this.props.match.params.id,
            Started: 0,
            counter: 0,
            points_ll: 0,
            points_rl: 0,
            points_lh: 0,
            points_rh: 0,
            points_oh: 0,
            points_ov: 0,
            points_kvidur: 0,
            points_face: 0,
            points_hne: 0,
            points_vhne: 0,
            points_munnhol: 0,
            points_ondun: 0,
            points_kynfaeri: 0,
            status: 0,
            Cause: 1,
            points: 0,
            fjoldi: 0,
            DiseaseActivity: 1,
            hex: "",
            reaction: 1,
            actiontaken: 9,
            message: "",
            messageCss: "",
            skilabodeitt: "",
            skilabodtvo: "",
            jsonstring: "",
            ended:"",
            currertid:0,
            absent:0,
            hexcode:"",
            duration:0,
            reactionid:0,
            causetxt:"",
            created:"",
            causename:"",
            patient:"",
            kynid:0,
        }
    }

    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get(global.config.api.url.live + '/editurgencyadmin/'+this.props.match.params.id, {
            headers: config
        })
        .then(res => this.setState({ 
            posts: res.data.data.urgency,
            patient: res.data.data.patient,
            kynid: res.data.data.patient_sex,
            isLoaded: true,
            created:res.data.data.urgency[0].created,
            causename:res.data.data.urgency[0].causename,
            Started: res.data.data.urgency[0].started,
            Cause: res.data.data.urgency[0].cause,
            value: res.data.data.urgency[0].registered,
            points_ll: res.data.data.urgency[0].ll,
            points_rl: res.data.data.urgency[0].rl,
            points_lh: res.data.data.urgency[0].lh,
            points_rh: res.data.data.urgency[0].rh,
            points_oh: res.data.data.urgency[0].oh,
            points_ov: res.data.data.urgency[0].ov,
            points_kvidur: res.data.data.urgency[0].kvidur,
            points_face: res.data.data.urgency[0].face,
            points_hne: res.data.data.urgency[0].hhne,
            points_vhne: res.data.data.urgency[0].vhne,
            points_munnhol: res.data.data.urgency[0].munnhol,
            points_ondun: res.data.data.urgency[0].Ondun,
            points_kynfaeri: res.data.data.urgency[0].kynfaeri,
            points:  res.data.data.urgency[0].points,
            DiseaseActivity:  res.data.data.urgency[0].diseaseactivity,
            hex:  res.data.data.urgency[0].colorcode,
            hexcode:  res.data.data.urgency[0].hex,
            reaction:  res.data.data.urgency[0].reaction,
            actiontaken:  res.data.data.urgency[0].actiontaken,
            status:  res.data.data.urgency[0].status,
            ended:  res.data.data.urgency[0].ended,
            currertid: res.data.data.urgency[0].id,
            absent: res.data.data.urgency[0].absent,
            duration: res.data.data.urgency[0].duration,
            reactionid:  res.data.data.urgency[0].reactionid,
            causetxt:  res.data.data.urgency[0].cause,
        }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    deleteUrgentcy() {
        if (window.confirm("Ertu viss um að þú viljir eyða völdu kasti?"))  {
            const customConfig = {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }, crossdomain: true
            };
            axios.delete(global.config.api.url.live + '/editurgency/'+this.props.match.params.id, customConfig)
            .then(() => {
                window.location = "/notandi/haexpert/";
            });
        }
    }

    render() {
        const { isLoaded, value,patient,status,kynid,id, Started, points_ll, points_rl,points_lh,points_rh,points_oh,points_ov,points_kvidur,points_face,points_hne,points_vhne,points_munnhol,points_ondun,points_kynfaeri,fjoldi,reaction,ended,hexcode,causename} = this.state;
        if (isLoaded) {
        return (
            <div>
                <main>
                    <SystemHeader title="Yfirlit" />
                    <div className={'alvarleikikasts l_' + hexcode}>
                        <p><strong>{ended}</strong></p>
                        <p>{reaction}</p>
                    </div><br /><br />
                    <React.StrictMode>
                        <div className="skra_mainwin">
                            <div className="merking">
                                <div className={"likami " + getLikaman(kynid) + " " + fjoldi}>
                                    <div className={"merki hofud val_"+ points_face} id="merkjaHofud"><p className="tip">Andlit og varir</p></div>
                                    <div className={"merki munnhol val_"+ points_munnhol} ><p className="tip">Tunga og háls</p></div>
                                    <div className={"merki ondunarfaeri val_"+ points_ondun}><p className="tip">Öndunarfæri</p></div>
                                    <div className={"merki meltingarfaeri val_"+ points_kvidur}><p className="tip">Meltingarfæri</p></div>
                                    <div className={"merki kynfaeri val_"+ points_kynfaeri}><p className="tip">Kynfæri</p></div>
                                    <div className={"merki vinstrihond val_"+ points_lh}><p className="tip">Vinstri Lófi</p></div>
                                    <div className={"merki haegrihond val_"+ points_rh}><p className="tip">Hægri Lófi</p></div>
                                    <div className={"merki vinstrio val_"+ points_ov}><p className="tip">Vinstri hönd</p></div>
                                    <div className={"merki haegrio val_"+ points_oh}><p className="tip">Hægri hönd</p></div>
                                    <div  className={"merki hne vinstri val_"+ points_vhne}><p className="tip">Vinstri Hné</p></div>
                                    <div  className={"merki hne val_"+ points_hne}><p className="tip">Hægri Hné</p></div>
                                    <div className={"merki vinstrifotur val_"+ points_ll}><p className="tip">Vinstri fótur</p></div>
                                    <div className={"merki haegrifotur val_"+ points_rl}><p className="tip">Hægri fótur</p></div>
                                    <input type="hidden" name="andlit" id="andlit" value={points_face} />
                                </div>
                            </div>
                        </div><div className="skra_upplysingar">
                            <div className="information_cause">
                                <h1 className="skrakast">{decrypt(patient)}</h1>
                                <p><strong>Dagsetning: </strong>{moment(value).format('Do MMMM  YYYY')}</p>
                                <p><strong>Vegna: </strong> {causename}</p>
                                <p><strong>Kast stóð í: </strong>:{Started} (klst)</p>
                            </div>
                            {athugaStodu(status, id, "open", "closed" )}
                        </div>
                    </React.StrictMode>
                </main>
            </div>
        )
    } else {
        return (
            <div>
                <main>
                    <SystemHeader title="Yfirlit" />
                </main>
            </div>
        )
        }
    }
}

function athugaStodu(p,id) {
    if (p === 0) {
        let thisIsMyCopy = "<span>Lokað kast</span>"
        return <small className='opid' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></small>
    } else {
        let thisIsMyCopy = "<span>Opið kast</span>";
        return <small className='lokad' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></small>
    }
}

function getLikaman(kynid) {

    if (kynid === 1) {
        return "kk";
    } else if (kynid === 2) {
        return "kvk";
    } else if (kynid === 3) {
        return "annad";
    }
}



export default ViewUrgency