import React, { Component } from 'react'

export class UmOkkur extends Component {


    render() {

        return (
            <main>
                <div className="wrapper zztop">
                    <h2>Um Okkur</h2>
                    <p>Fruma er sprotafyrirtæki stofnað af sérfræðingum vítt og breitt úr heilbrigðisstéttinni. Þessi hópur af þaulreyndu fagfólki hefur aðeins eitt markmið - að auka velsæld sinna sjúklinga til frambúðar.</p>
                    <h2>Stjórn Frumu</h2>
                    <p>Stjórn Frumu er skipuð fólki með fjölbreyttan bakgrunn úr atvinnulífinu, bæði úr einkageiranum og hinum opinbera.</p>
                    <p><strong>Mr. Gunnar Armannsson</strong></p>
                    <p>Gunnar er stjórnarformaður Frumu. Hann lauk embættisprófi í lögfræði frá Háskóla Íslands árið 1993 og er með MBA gráðu frá sama skóla. Í framhaldi var hann forstöðumaður innheimtusviðs hjá Tollstjóranum í Reykjavík allt til ársins 2002. Þá var Gunnar framkvæmdastjóri Læknafélags Íslands á árunum 2002 til 2009. Frá árinu 2009 hefur hann verið framkvæmdastjóri PrimaCare ehf., nýsköpunarfyrirtækis á sviði einkarekinnar heilbrigðisþjónustu. Hann hefur einnig starfað sem ráðgjafi fyrir VHE ehf., fyrirtæki sem býður upp á lausnir á véltæknisviði.</p>
                    <p><strong>Mr.  David A. Gunnarsson</strong></p>
                    <p>Davíð lauk prófi í vélaverkfræði frá KTH í Stokkhólmi árið 1969 og í rekstrarhagfræði frá háskólanum í Stokkhólmi árið 1971. Þá lauk hann prófi í stjórnsýslufræði frá síðarnefndum skóla ári síðar. Hann var forstjóri Landspítalans á árunum 1980 til 1985 og ráðuneytisstjóri í heilbrigðis- og tryggingamálaráðuneyti á árunum 1995 til 2007. Davíð hefur gegnt ýmsum nefndarstörfum í gegnum tíðina. Hann var kosinn í fastanefnd Evrópusvæðis Alþjóða heilbrigðisstofnunarinnar frá 1997 til 2000. Hann var kjörinn í aðalstjórn stofnunarinnar frá 2003 til 2006 og sem stjórnarformaður hennar árið 2004 til 2005. </p>
                    <p><strong>Mr. Unnar Hjaltason</strong></p>
                    <p>Unnar er forstjóri og eigandi VHE ehf., fyrirtæki sem býður upp á lausnir á véltæknisviði. VHE ehf. er stærsta vélsmiðja landsins og hefur selt íslenskt hugvit og tækjabúnað til fjölda erlendra álfyrirtækja.</p>
                </div>
            </main>
        );
        
    }

}

export default UmOkkur