import React, { Component } from 'react'
import DateTimePicker from 'react-datetime-picker';
import Topbar from '../templates/Topbar';
import './AddUrgency.css';

import { getKyn } from '../../Utils/Common';

const kynid = getKyn();

export class AddRheum extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: new Date(),
            r1:1,
            v1:1,
            r2:1,
            v2:1,
            r3:1,
            v3:1,
            Started: 0,
            counter: 0,
            Malarrash: 0,
            Discoidrash: 0,
            Gottronsign: 0,
            Heliotroperash:0,
            Psoriasis:0,
            Other:0,
            points: 0,
            fjoldi: 0,
            DiseaseActivity: 1,
            hex: 1,
            reaction: 1,
            actiontaken: 9,
            message: "",
            messageCss: "",
            skilabodeitt: "",
            skilabodtvo: "",
            jsonstring: "",
        }

        this.getTotal = this.getTotal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setMalarrash = this.setMalarrash.bind(this);
        this.setDiscoidrash = this.setDiscoidrash.bind(this);
        this.setGottronsign = this.setGottronsign.bind(this);
        this.setHeliotroperash = this.setHeliotroperash.bind(this);
        this.setPsoriasis = this.setPsoriasis.bind(this);
        this.setStarted = this.setStarted.bind(this);
        this.setOther = this.setOther.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setV1 = this.setV1.bind(this);
        this.setR1 = this.setR1.bind(this);
        this.setV2 = this.setV2.bind(this);
        this.setR2 = this.setR2.bind(this);
        this.setV3 = this.setV3.bind(this);
        this.setR3 = this.setR3.bind(this);
        this.setMessage = this.setMessage.bind(this);
    }

    setValue(p) { this.setState({value: p}); }

    setStarted(p) { this.setState({Started: p.target.value}); }
    setPsoriasis(p) { this.setState({Psoriasis: p.target.value});  }
    setMalarrash(p) { this.setState({Malarrash: p.target.value});}
    setDiscoidrash(p) { this.setState({Discoidrash: p.target.value});}
    setGottronsign(p) { this.setState({Gottronsign: p.target.value});}
    setHeliotroperash(p) { this.setState({Heliotroperash: p.target.value});}
    setOther(p) { this.setState({Other: p.target.value});}

    // Points
    setV1() {
        const {v1} = this.state;
        if (v1 === 1) {
            this.setState({v1: 4});
        } else {
            this.setState({v1: 1});
        }
    }

    setR1() {
        const {r1} = this.state;
        if (r1 === 1) {
            this.setState({r1: 4});
        } else {
            this.setState({r1: 1});
        }
    }

    setV2() {
        const {v2} = this.state;
        if (v2 === 1) {
            this.setState({v2: 4});
        } else {
            this.setState({v2: 1});
        }
    }

    setR2() {
        const {r2} = this.state;
        if (r2 === 1) {
            this.setState({r2: 4});
        } else {
            this.setState({r2: 1});
        }
    }

    setV3() {
        const {v3} = this.state;
        if (v3 === 1) {
            this.setState({v3: 4});
        } else {
            this.setState({v3: 1});
        }
    }

    setR3() {
        const {r3} = this.state;
        if (r3 === 1) {
            this.setState({r3: 4});
        } else {
            this.setState({r3: 1});
        }
    }

    // Genger, age, Duration, malar rash, discoid rash, gottron's sign,Heliotrope rash,Psoriasis, other

    setMessage(p) {
        this.setState({message:p, messageCss: "on"});
    }

    getTotal() { }

    handleSubmit(event) {
        event.preventDefault();
    };

    render() {
        const {value,Started,Malarrash,r1,v1,r2,v2,r3,v3,points_face,Discoidrash,Other,Psoriasis,Heliotroperash,Gottronsign,fjoldi,message,messageCss,skilabodeitt, skilabodtvo} = this.state;

        return (
            <div>
                <Topbar title=" " heiti="GigtRáður - Skrá tilfelli"/>
                <main>
                    <React.StrictMode>
                    <form onSubmit={this.handleSubmit}>
                        <div className="skra_mainwin">
                            <div className="merking">
                                <div className={"likami " + getLikaman(kynid) + " " + fjoldi}>
                                    <div className={"merki r1 val_"+ r1} id="merkjaR1" onClick={() => this.setR1()}><p className="tip">Hægri öxl</p></div>
                                    <div className={"merki v1 val_"+ v1} id="merkjaV1" onClick={() => this.setV1()}><p className="tip">Vinstri öxl</p></div>
                                    <div className={"merki r2 val_"+ r2} id="merkjaR1" onClick={() => this.setR2()}><p className="tip">Hægri olnbogi</p></div>
                                    <div className={"merki v2 val_"+ v2} id="merkjaV1" onClick={() => this.setV2()}><p className="tip">Vinstri olnbogi</p></div>
                                    <div className={"merki r3 val_"+ r3} id="merkjaR1" onClick={() => this.setR3()}><p className="tip">Hægri ulnliður</p></div>
                                    <div className={"merki v3 val_"+ v3} id="merkjaV1" onClick={() => this.setV3()}><p className="tip">Vinstri ulnliður</p></div>
                                    <input type="hidden" name="andlit" id="andlit" value={points_face} />
                                </div>
                            </div>
                        </div><div className="skra_upplysingar">
                            <h3 className="page-title hlid">Dagsetning </h3>
                            <div>
                                <DateTimePicker  onChange={(e) => this.setValue(e)} value={value} format="y-MM-dd" disableClock={null} />
                            </div>
                            <h3 className="page-title hlid">Hversu langt er síðan kastið byrjaði? (klst)</h3>
                            <select name="Started" value={Started}  onChange={(e) => this.setStarted(e)}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">+7 klst</option>
                            </select>
                            <h3 className="page-title hlid">Malar rash</h3>
                            <select name="Malarrash" value={Malarrash}  onChange={(e) => this.setMalarrash(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3 className="page-title hlid">Discoid rash</h3>
                            <select name="Discoidrash" value={Discoidrash}  onChange={(e) => this.setDiscoidrash(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3 className="page-title hlid">Gottron's rash</h3>
                            <select name="Gottronsign" value={Gottronsign}  onChange={(e) => this.setGottronsign(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3 className="page-title hlid">Heliotrope rash</h3>
                            <select name="Heliotroperash" value={Heliotroperash}  onChange={(e) => this.setHeliotroperash(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3 className="page-title hlid">Psoriasis</h3>
                            <select name="Psoriasis" value={Psoriasis}  onChange={(e) => this.setPsoriasis(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3 className="page-title hlid">Other specific skin or hair symptoms</h3>
                            <select name="Other" value={Other}  onChange={(e) => this.setOther(e)}>
                                <option value="0">Nei</option>
                                <option value="1">Já</option>
                            </select>
                            <h3>Number of selected joins</h3>
                            <h3>Outcomes and Recommendation</h3>

                            <p><button type="submit" className="buatilnotenda">Skrá tilfelli</button></p>

                            <div className={"message " + messageCss}>
                                <div className="skilabod">
                                    {message ? <p>{message}</p> : null}
                                    Þetta kast er: <strong>{skilabodeitt}</strong><br/>
                                    Þú þart að: <br/>
                                    {skilabodtvo}<br/><br/>
                                    <a href="/notandi/haexpert">Loka</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    </React.StrictMode>
                </main>
            </div>
        )
    }
}

function getLikaman(kynid) {

    if (kynid === "1") {
        return "rheum";
    } else if (kynid === "2") {
        return "rheum";
    } else if (kynid === "3") {
        return "rheum";
    }
}



export default AddRheum

