import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken } from '../../Utils/Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class IgExpert extends Component {
    state = {
        posts: [],
        isLoaded: false,
        checked:false,
        perpage: 0,
        createdon: "",
        q1:0,
        q2:0,
        q3:0,
        q4:0,
        q5:0,
        q6:0,
        q7:0,
        q8:0,
        q9:0,
        q10:0,
        q11:0,
        q12:0,
        whatsyklalyf:"",
        aukaverkanir:"",
        einkenni:""
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/igexpert/'+this.props.match.params.id, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({
            posts: res.data.data.ige,
            q1: res.data.data.ige[0].q1,
            q2: res.data.data.ige[0].q2,
            q3: res.data.data.ige[0].q3,
            q4: res.data.data.ige[0].q4,
            q5: res.data.data.ige[0].q5,
            q6: res.data.data.ige[0].q6,
            q7: res.data.data.ige[0].q7,
            q8: res.data.data.ige[0].q8,
            q9: res.data.data.ige[0].q9,
            q10: res.data.data.ige[0].q10,
            q11: res.data.data.ige[0].q11,
            q12: res.data.data.ige[0].q12,
            createdon: res.data.data.ige[0].createdon,
            whatsyklalyf: res.data.data.ige[0].syklalif,
            aukaverkanir: res.data.data.ige[0].aukaverkanir,
            einkenni: res.data.data.ige[0].einkenni,
            isLoaded: true,
            perpage: res.data.data.per_page
        }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, whatsyklalyf, einkenni,createdon} = this.state;
        const { t } = this.props;
        console.log(posts);
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <Topbar title={t('menu.igexpert')} heiti=""/>
                        <main>
                            <div className='greiningarprof'>
                                <h1>Skoða mat á mótefnameferð</h1> 
                                <p className='lysing'>Tekið þann {moment(createdon).format('Do MMM YYYY')}</p>
                                <br/>
                                <div className='issyklalyfquestion'>
                                    <h3 className="questions">Fyrirbyggjandi sýklalyf?</h3>
                                    <div className='syklalyfquestion'>
                                    </div>
                                    <div className={'issyklalyf istrue'}><h3 className="questions ans">{whatsyklalyf}</h3></div>
                                </div>
                                <h3 className="questions">Fjöldi eyrna-, ennis - eða kinnholusýkinga</h3>
                                <div className={"spurningar_checkboxes q1val" + q1}>
                                    <div className="for-checkbox-tools q1_0"><i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q1_1"><i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q1_2"><i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div></div>
                                </div>

                                <h3 className="questions">Fjöldi barka-/berkjubólgna meðhöndlaðar með sýklalyfjum</h3>
                                <div className={"spurningar_checkboxes q2val" + q2}>
                                    <div className="for-checkbox-tools q2_0"><i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q2_1"><i className="fa-regular fa-face-frown-slight"></i> <div>2<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q2_2"><i className="fa-regular fa-face-confounded"></i> <div>3<br/> sinnum eða oftar</div></div>
                                </div>
                                
                                <h3 className="questions">Fjöldi lungnabólgna</h3>
                                <div className={"spurningar_checkboxes q3val" + q3}>
                                    <div className="for-checkbox-tools q3_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q3_2"><i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q3_3"><i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Fjöldi húðsýkinga</h3>
                                <div className={"spurningar_checkboxes q4val" + q4}>
                                    <div className="for-checkbox-tools q4_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q4_1"><i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q4_2"><i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div></div>
                                </div>

                                <h3 className="questions">Fjöldi herpessýkinga <small>(t.d. frunsur)</small></h3>
                                <div className={"spurningar_checkboxes q5val" + q5}>
                                    <div className="for-checkbox-tools q5_0"><i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q5_1"><i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q5_2"><i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div></div>
                                </div>

                                <h3 className="questions">Fjöldi ígerða (abcessa) sem þurfti að hleypa út</h3>
                                <div className={"spurningar_checkboxes q6val" + q6}>
                                    <div className="for-checkbox-tools q6_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q6_1"><i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q6_2"><i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Fjöldi sveppasýkinga <small>(t.d. í húð eða kynfærum)</small></h3>
                                <div className={"spurningar_checkboxes q7val" + q7}>
                                    <div className="for-checkbox-tools q7_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q7_1"><i className="fa-regular fa-face-frown-slight"></i> <div>1 til 2<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q7_2"><i className="fa-regular fa-face-confounded"></i> <div>3<br/> sinnum eða oftar</div></div>
                                </div>
                                
                                <h3 className="questions">Fjöldi tannholdssýkinga</h3>
                                <div className={"spurningar_checkboxes q8val" + q8}>
                                    <div className="for-checkbox-tools q8_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q8_1"><i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q8_2"><i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Hve oft niðurgangur og/eða blóð í hægðum</h3>
                                <div className={"spurningar_checkboxes q9val" + q9}>
                                    <div className="for-checkbox-tools q9_0"><i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q9_1"><i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q9_2"><i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Fjöldi sýkinga í heila, liðum eða beini</h3>
                                <div className={"spurningar_checkboxes q10val" + q10}>
                                    <div className="for-checkbox-tools q10_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q10_3"><i className="fa-regular fa-face-confounded"></i> <div>1<br/> sinni eða oftar</div></div>
                                </div>
                                
                                <h3 className="questions">Hve oft sýklalyf í æð eða innlögn á spítala</h3>
                                <div className={"spurningar_checkboxes q11val" + q11}>
                                    <div className="for-checkbox-tools q11_0"><i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q11_2"><i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q11_3"><i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Fjöldi sýklalyfjakúra</h3>
                                <div className={"spurningar_checkboxes q12val" + q12}>
                                    <div className="for-checkbox-tools q12_0"><i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div></div>
                                    <div className="for-checkbox-tools q12_1"><i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div></div>
                                    <div className="for-checkbox-tools q12_2"><i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div></div>
                                </div>
                                <h3 className="questions">Önnur einkenni<br/><small>(t.d. aukaverkanir af meðferð)</small></h3>
                                <p>{einkenni}</p>
                            </div>
                            
                        </main>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <h3>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <p>Ekkert próf hefur verið tekið</p>
                        <div className="nyttprof">
                            <a href='/notandi/hae/lifsgaedi/'>Taka próf</a>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}
/*
function getHeildarskor(heildarskor) {
    let heildarskorIcon = "";
    if (heildarskor >= 3) {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (heildarskor === 2) {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else if (heildarskor === 1) {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}
*/
export default withTranslation()(IgExpert);