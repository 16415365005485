import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import SystemHeader from './SystemHeader';
import { Link } from 'react-router-dom';
import UserDropdown from './components/UserDropdown';

import moment from "moment";
import 'moment/locale/is';
import { decrypt } from '../../helpers/decrypt'
// removeAdminSession
import { getToken, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class IGExpert extends Component {

    state = { posts: [], isLoaded: false, perpage: 0, userid: this.props.match.params.id }
    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        var selectedUser = "";

        if(this.props.match.params.id !== undefined) {
            selectedUser = this.props.match.params.id;
        }

        console.log(this.props.match.params.id);

        axios.get(global.config.api.url.live + '/ige/'+selectedUser, {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.ige, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                //removeAdminSession();
                //window.location.reload();
                console.log("Error geting data");
            }
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded, userid} = this.state;
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <SystemHeader title="IgXpert virknimat"/>
                        <main>
                            <UserDropdown url="igexpert" selected={userid} />
                            <div className="urgencies listinn">
                                <table className='qualityoflife'>
                                    <thead>
                                        <tr>
                                            <th className='tl' colSpan={2}>Mat</th>
                                            <th>Sjúklingur</th>
                                            <th>{t('date')}</th>
                                            <th className='tl'>Fyrirbyggjandi sýklalyf?</th>
                                            <th className='tl'>Einkenni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {posts.map(post =>
                                        <tr key={post.id}>
                                            <td className='tl' width="1%"><div className='heildarskor_mynd'><i className={getHeildarskor(post.score)}></i></div> </td>
                                            <td><a className={getClass(post.score)} href={"/admin/igexpert/v/" + post.id}>{getlinktext(post.score)}</a></td>
                                            <td className='tl'><Link to={"/admin/patients/" + post.user} >{decrypt(post.name)}</Link></td>
                                            <td className='grey'>{moment(post.createdon).format('Do MMM YYYY')}</td>
                                            <td className='tl'>{post.syklalif}</td>
                                            {post.einkenni.length > 45 ?<td className='tl'>{post.einkenni.slice(0, 45)}...</td>  : <td className='tl'>{post.einkenni}</td>}
                                            <td className='tl'><a href={"/admin/igexpert/v/" + post.id} className="skodamat">Skoða</a></td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div>
                        <SystemHeader title="IgXpert virknimat"/>
                        <main>
                            <UserDropdown url="igexpert" selected={userid} />
                            <div className="urgencies listinn">
                                <table className='qualityoflife'>
                                    <thead>
                                        <tr>
                                            <th className='tl'>{t('total_score')}</th>
                                            <th>{t('date')}</th>
                                            <th>Sjúklingur</th>
                                            <th className='tl'>Fyrirbyggjandi sýklalyf?</th>
                                            <th className='tl'>Einkenni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="5">Engin innsend gögn.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </main>
                    </div>
                )
            }
        } else {
            return (
                <div></div>
            )
        }
    }
}

function getHeildarskor(heildarskor) {
    let heildarskorIcon = "";
    if (heildarskor >= 3) {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (heildarskor === 2) {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else if (heildarskor <= 1) {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}

function getlinktext(heildarskor,) {
    let heildarskorText = "";
    if (heildarskor >= 3) {
        heildarskorText = 'Alvarlegt';
    } else if (heildarskor === 2) {
        heildarskorText = 'Vægt';
    } else if (heildarskor <= 1) {
        heildarskorText = 'Eðlilegt';
    }
    return heildarskorText;
}

function getClass(heildarskor) {
    let heildarskorText = "";
    if (heildarskor >= 3) {
        heildarskorText = 'kast_b80000';
    } else if (heildarskor === 2) {
        heildarskorText = 'kast_c5e70b';
    } else if (heildarskor <= 1) {
        heildarskorText = 'kast_009c4f';
    }
    return heildarskorText;
}


export default withTranslation()(IGExpert);