import React, { Component } from 'react'

import './MainWin.css';

export class MainWin extends Component {

    render() {

        return (
            <div>
                <section className='banner'>
                    <div className='wrapper'>
                        <h3 className="yfirfyrirsogn">Fruma</h3>
                        <h2 className="fyrirsogn"><strong>Greiningartæki</strong> sem markar tímamót í <strong>heilbrigðiskerfinu</strong> á Íslandi</h2>
                        <p className="undirfyrirsogn">Fruma eykur lífsgæði sjúklinga og veitir heilbrigðisstarfsfólki allar upplýsingar með einum smelli.</p>
                        <div className="adgangur">
                            <a href="/login" className="takki">Innskrá</a>
                        </div>
                    </div>
                </section>
                <main className="website">
                    <div className="aherslur">
                        <h2>Hvernig getur <strong>Fruma</strong> hjálpað þér</h2>
                        <div className="ahersla">
                            <figure style={{ backgroundImage: 'url("/img/laboratory-gd70e2ff58_1920.jpg")' }}></figure>
                            <h3>Gæði</h3>
                            <p>Fruma er greiningartæki sem hjálpar heilbrigðisstarfsfólki og sjúklingum með sjaldgæfa sjúkdóma að einfalda erfitt ferli og gera samskipti gagnsærri og skýrari.</p>
                        </div>
                        <div className="ahersla">
                            <figure style={{ backgroundImage: 'url("/img/laboratory-gff1baf7ac_1920.jpg")' }}></figure>
                            <h3>Allar upplýsingar</h3>
                            <p>Með Frumu geta sjúklingar nálgast sína veikindasögu, séð hvaða lyf þeir eiga að taka og hvenær og haft samskipti við sinn lækni - allt á einum stað.</p>
                        </div>
                        <div className="ahersla">
                            <figure style={{ backgroundImage: 'url("/img/eye-g2f5bb7cea_1280.jpg")' }}></figure>
                            <h3>Frumreglan</h3>
                            <p>Við setjum sjúklinga í fyrsta sæti í öllu sem við gerum. Við höfum viðað að okkur sérfræðingum úr öllum stéttum læknavísindanna og þannig búið til hágæða greiningartæki sem uppfyllir allar kröfur og viðmið.</p>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default MainWin