import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';
import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken } from '../../Utils/Common';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class Tasks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skilabod:"",error:"",posts: [], isLoaded: false, showBtn: true, showBtnCss: "disabled"
        }
    }

    componentDidMount () {
        axios.get(global.config.api.url.live + '/messenger',{
            headers: {
                'Authorization': token
            }
        })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    _handleSkilabod = e => {
        this.setState({ skilabod: e  });
        if (e.length > 2) {
            this.setState({ showBtn: false, showBtnCss: "" });
        } else {
            this.setState({ showBtn: true, showBtnCss: "disabled" });
        }
        console.log(e.length);
    }

    handlePageClick = (event) => {
        event.preventDefault();
        const {skilabod } = this.state;
        try {
            fetch(global.config.api.url.live + "/tasks", {
                method: "POST",
                headers: { 'Authorization': token,'Content-Type': 'application/json' },
                crossdomain: true,
                body: JSON.stringify({
                    title: "title",
                    description: skilabod,
                    completed: 'N',
                    message_type: 0,
                    message_to: 0,
                    system_message: "1"
                }),
            });
            axios.get(global.config.api.url.live + '/messenger',{
                headers: {
                    'Authorization': token
                }
            })
            .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true, skilabod:"", error: "", showBtn: true, showBtnCss: "disabled" }))
        } catch (err) {
            this.setState({ error: "Villa" })
        };
};

    render() {
        const { t } = this.props;
        const { posts, isLoaded, skilabod, error, showBtn, showBtnCss} = this.state;
        if (isLoaded) {
            if (posts.length > 0) {
                return (
                    <div>
                        <Topbar title="menu.samskipti" heiti="" />
                        {error}
                        <main>
                            <div className='messenger'>
                            {posts.map(post =>
                                <div className={"item " + post.read + " messagetype_" + post.system} key={post.id}> 
                                    <div className="upplysingar">
                                        <p className='skilabod_tima'>{moment(post.deadline).format('Do MMM YYYY H:mm:ss')}</p>
                                        <div className='skilabodin'>
                                            <p>{post.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className='messenger_input'>
                                <input type='text'  value={skilabod} placeholder="Ný skilaboð" onChange={e => this._handleSkilabod(e.target.value)} />
                                <button onClick={this.handlePageClick} className={showBtnCss} disabled={showBtn}>Senda</button>
                            </div>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Topbar title="menu.samskipti" heiti="" />
                        <main>
                            <div className='messenger'>
                                <div className="tomt"><strong>Þú átt ekki nein skilaboð.</strong><br/> Ef þú hefur fyrirspurn eða vantar aðstoð getur þú haft samband með því að fylla í reitina hér fyrir neðan.</div>
                            </div>
                            <div className='messenger_input'>
                                <input type='text'  value={skilabod} placeholder="Ný skilaboð" onChange={e => this._handleSkilabod(e.target.value)} />
                                <button onClick={this.handlePageClick} className={showBtnCss} disabled={showBtn}>Senda</button>
                            </div>
                        </main>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <Topbar title={t('menu.samskipti')} heiti="" />
                    <main>
                        <h1>Er að ná í gögn</h1>
                    </main>
                </div>
            )
        }
    }
}

export default withTranslation()(Tasks);