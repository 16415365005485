import React, { Component } from 'react'
import axios from 'axios';

import { getToken } from '../../../../Utils/Common';

const token = getToken();

export class MedicineWeeks extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get(global.config.api.url.live + '/medicine/weeks/',{
            headers: { 
                'Authorization': token
            }
        })
        .then(res => this.setState({ posts: res.data.data.medicine, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            if (posts) {
                return (
                    <div className="lyfin_list">
                        {posts.map(post =>
                            <div key={post.id}>{post.name} - {post.lysing}</div>
                        )}
                    </div>
                )
            } else {
                return (<div>Engin lyf skráð</div>)
            }
        } else {
            return (
                <div className="lyfin_list">
                    Næ í gögn
                </div>
            )
        }
    }
}

export default MedicineWeeks