const crypto = require('crypto');
const AES_METHOD = 'aes-256-cbc';
const key = 'R5wyBzfgzUIuXZFShJyikaWbLJtIVq66';
const myIv = 'aaf46cf65a613537b327a76428988112';

export function encrypt(text) {
    if (process.versions.openssl <= '1.0.1f') { throw new Error('OpenSSL Version too old, vulnerability to Heartbleed') }
    let iv = new Buffer(myIv, 'hex');
    let cipher = crypto.createCipheriv(AES_METHOD, new Buffer(key), iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('hex');
}

export function decrypt(text) {
    if (!text) {
        return "";
    } else {
        text = myIv + ':' + text;
        let textParts = text.split(':');
        let iv = new Buffer(textParts.shift(), 'hex');
        let encryptedText = new Buffer(textParts.join(':'), 'hex');
        let decipher = crypto.createDecipheriv('aes-256-cbc', new Buffer(key), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }
}