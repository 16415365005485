import React, { Component } from "react";
import Chart from "react-apexcharts";

class UserGrafStadsetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [this.props.face,this.props.munnhol,this.props.ondun, this.props.kvidur, this.props.kynfaeri, this.props.lh, this.props.rh, this.props.ov, this.props.oh, this.props.vhne, this.props.hne, this.props.ll, this.props.rl],
            options: {
                colors: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324'],
                labels: ['Andlit og varir', 'Tunga og háls ', 'Öndunarfæri', 'Meltingarfæri ','Kynfæri','Vinstri Lófi','Hægri Lófi','Vinstri hönd','Hægri hönd','Vinstra Hné','Hægra Hné','Vinstri fótur','Hægri fótur'],
                chart: {
                    id: "basic-bar",
                    type: 'donut',
                    height: 440,
                    toolbar: {
                        show: false,
                    },
                },
                legend: {
                    position: 'bottom'
                  }
            },
        };
    }

    render() {
        return (
            <div className="app">
            <div className="row">
                <div className="mixed-chart">
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="pie"
                    height={440}
                />
                </div>
            </div>
            </div>
        );
    }
}

export default UserGrafStadsetting;