import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { removeUserSession } from '../../Utils/Common';

function MobileMenu() {
    const [isShown, setIsShown] = useState("closed");
    let handleChange = async () => {
        if (isShown === "closed") {
            setIsShown("open");
        } else {
            setIsShown("closed");
        }
    }

    let handleLogout = async (e) => {
        e.preventDefault();
        removeUserSession();
    }

    return (
        <div>
            <ul className="menu">
                <li><NavLink to="/notandi/dashboard" activeClassName="selected" onClick={handle => {handleChange();}}>Yfirlit</NavLink></li>
                <li><NavLink to="/notandi/samskipti" activeClassName="selected" onClick={handle => {handleChange();}}>Samskipti</NavLink></li>
                <li><NavLink to="/notandi/skyrsla" activeClassName="selected" onClick={handle => {handleChange();}}>Skýrsla</NavLink></li>
                <li><NavLink to="/notandi/hae/lifsgaedi/" activeClassName="selected" onClick={handle => {handleChange();}}>Lífsgæði</NavLink></li>
                <li className="divider"><NavLink to="/notandi/haexpert" activeClassName="selected" onClick={handle => {handleChange();}}>HAExpert</NavLink></li>
                <li className="btn"><NavLink to="/notandi/minsida" activeClassName="selected" onClick={handle => {handleChange();}}>Mín síða</NavLink></li>
                <li className="btn "><a href="/notandi/haexpert/skra">Skrá bráðakast</a></li>
                <li className="btn "><button onClick={handleLogout}><i className="fa-solid fa-power-off"></i> Útskrá</button></li>
            </ul>
            <div className="logingout"></div>
        </div>
    )
}

export default MobileMenu;