import React, { Component } from 'react'
import Appointments from './user/components/Appointments';
import Topbar from '../templates/Topbar';


export class Timapantanir extends Component {
    state = { posts: [], isLoaded: false, userid: null };

    render() {
        return (
            <div>
                <Topbar title="home.appointments" heiti="" />
                <main>
                    <div className="yfirlit">
                        <Appointments />
                    </div>
                </main>
            </div>
        )
    }
}

export default Timapantanir;