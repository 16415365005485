import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken} from '../../Utils/Common';
import ChangePass from './user/ChangePass';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class User extends Component {

    state = { posts: [], isLoaded: false };
    componentDidMount () {
        axios.get(global.config.api.url.live + '/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded} = this.state;
        console.log(posts)
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.mypage" heiti=""/>
                    <main>
                        <div className="yfirlit">
                            <h3 className="section_heading"> {t('home.general')}</h3>
                            <h3 className="section_heading">{t('newpassword')}</h3>
                            <div className="changePass">
                                <ChangePass/>
                            </div>
                        </div>

                    </main>
                </div>
            )
        }else {
            return (
                <main></main>
            )
        }
    }
}

export default withTranslation()(User);