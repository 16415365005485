import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import ReactPaginate from 'react-paginate';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../Utils/Common';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAExpert extends Component {

    state = { posts: [], isLoaded: false, perpage: 0, rowscount: 0, open: 0 }
    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get(global.config.api.url.live + '/urgency/page/0', {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page, rowscount: res.data.data.rows_all, open: res.data.data.Open }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    handlePageClick = (event) => {

        let valid = event.selected ;
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get(global.config.api.url.live + '/urgency/page/'+valid, {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page, rowscount: res.data.data.rows_all }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    };

    render() {

        const { t } = this.props;
        const { posts, isLoaded, rowscount ,perpage, open } = this.state;
        console.log(posts);
        let pages = rowscount / perpage + 1;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.haexpert" heiti=""/>
                    <main>
                        <h1>{t('Inflammationattacks')} <span>({rowscount})</span> <Link to="/notandi/haexpert/skra" className="addUrgency"><i className="fa-solid fa-square-plus"></i> {t('inflammatory')}</Link></h1>
                        <h3 className='opinkost'>Þú átt <strong>{open}</strong> opin köst sem þú verður að loka</h3>
                        <ul className='filter hidethis'>
                            <li><button>Sýna opin köst ({open})</button></li>
                        </ul>
                        <table className='qualityoflife'>
                            <thead>
                                <tr>
                                    <th>{t('case')}</th>
                                    <th class="hideonmobile"> </th>
                                    <th>{t('registered')}</th>
                                    <th class="hideonmobile">{t('reaction')}</th>
                                    <th class="hideonmobile">{t('Reason')}</th>
                                    <th>Staðsetningar</th>
                                    <th className='tr'>{t('status')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts.map(post =>
                                    <tr key={post.id}>
                                        <td width="1%"><div className='heildarskor_mynd'><i className={getIcon(post.ended)}></i></div></td>
                                        <td class="hideonmobile"><Link to={"/notandi/haexpert/uppfaera/"+post.id} className={"kast_"+post.hex}>{fixEndedName(post.ended)}  </Link></td>
                                        <td><Link to={"/notandi/haexpert/uppfaera/"+post.id} className="registered">{moment(post.registered).format('Do MMM YYYY')}</Link></td>
                                        <td class="hideonmobile">{post.reaction}</td>
                                        <td class="hideonmobile">{post.cause}</td>
                                        <td>{post.where}</td>
                                        <td className='tr'>{athugaStodu(post.status, post.id, t('open'), t('closed') )}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='pagination'>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={Math.floor(pages)}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    <Topbar title={t('menu.haexpert')}/>
                    <main>
                        <h1>HAExpert <Link to="/haexpert/skra" className="addUrgency">{t('inflammatory')}</Link></h1>
                        <div className="urgencies">
                            <div className="urgency">
                                Hleð inn...
                            </div>
                        </div>
                    </main>
                </div>
            )
        }
    }
}

function athugaStodu(p,id,open,closed) {
    if (p === 0) {
        let thisIsMyCopy = "<a href='/notandi/haexpert/Loka/"+id+"'>"+ open +"</a>"
        return <div className='opid' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    } else {
        let thisIsMyCopy = "<span>"+ closed +"</span>";
        return <div className='lokad' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    }
}

function getIcon(ended) {
    let heildarskorIcon = "";
    if (ended === "Alvarleg") {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (ended === "Miðlungs") {
        heildarskorIcon = "fa-regular fa-face-anguished";
    } else if (ended === "Óveruleg") {
        heildarskorIcon = "fa-regular fa-face-expressionless";
    } else if (ended === "Væg") {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}

function fixEndedName(ended) {
    let newname = "";
    if (ended === "Alvarleg") {
        newname = "Alvarlegt";
    } else if (ended === "Miðlungs") {
        newname = "Miðlungs";
    } else if (ended === "Óveruleg") {
        newname = "Óverulegt";
    } else if (ended === "Væg") {
        newname = "Vægt";
    }
    return newname;
}

export default withTranslation()(HAExpert);