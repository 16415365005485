import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import { Link } from 'react-router-dom';
export class EducationalMaterial extends Component {
    render() {
        return (
            <div>
                <Topbar title="Fræðsluefni" heiti=""/>
                <main>
                    <div className='fraeefnid'>
                        <h1>Dagbók fyrir mótefnagjafir undir húð</h1>
                        <p >Hver síða sýnir vikutímabil, á hverri síðu skal skrá dagsetningu mótefnagjafar, lotunúmer, skammt í ml og hugsanlegar aukaverkanir. Ef þú tekur lyf samdægurs til að koma í veg fyrir aukaverkanir eða til þess að milda aukaverkanir skal skrá það niður. Sýkingar, veikindi og fjarvistir frá vinnu/skóla eru einnig gagnlegar upplýsingar til þess að meta gagnsemi meðferðar. Einnig skaltu skrá ef þú ert á sýklalyfjum.</p>
                    
                        <h3>Stungustaðir</h3>
                        <p>
                            <strong className='graenusvaedin'>Grænu svæðin</strong> sýna stungustaði sem er æskilegast að nota<br/>
                            <strong className='blausvaedin'>Bláu svæðin</strong> sýna stungustaði sem er einnig hægt að nota
                        </p>
                        <img src='/img/stungusvaedi.png' className='responsiveimg' alt='Stungustaðir' />
                        <h3 className='dagbokinfo'>Val á stungustað:</h3>
                        <ul className='dagbokinfo'>
                            <li>Ekki velja stungustað þar sem húðin er rauð, hörð, marin eða aum.</li>
                            <li>Ekki velja stungustað á húðsvæði með örum eða sliti.</li>
                            <li>Hafðu 5 cm á milli stungustaða og 5 cm frá nafla.</li>
                        </ul>
                        <p className='dagbokinfo'>Vökvasettin er hægt að fá með 1-4 örmum. Innrennslismagnið á hverjum stungustað er breytilegt, en mælt er með því að skipta niður skömmtum sem eru 30 ml eða meira á nokkra stungustaði.</p>
                        <img src='/img/vokvasettin.png' className='responsiveimg' alt='Vökvasettin' /><h2>Frekari upplýsingar</h2>
                        <p>Á <a href="https://www.landspitali.is/sjuklingar-adstandendur/deildir-og-thjonusta/gongudeild-onaemisfraedideildar/">heimasíðu ónæmisfræðideildar Landspítala</a> er að finna nánari upplýsingar.</p>

                        <Link to="/notandi/fraedsluefni" className='lesameira'>Til baka í <strong>Fræðsluefni</strong></Link>
                    </div>
                </main>
            </div>
        )
    }
}

export default EducationalMaterial;