import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import { getToken } from  '../../../Utils/admincommon';
import SystemHeader from './SystemHeader';
import UserGraf from './components/UserGraf';
import PatientForm from './components/PatientForm';

document.body.classList.add("admin");

const token = getToken();

const Patient = () => {
    const params = useParams();

    useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': token },
            body: JSON.stringify({ uid: params.id, page: "/admin/patient" })
        };
        fetch('https://api.fruma.is/footprint', requestOptions).then(response => response.json());
    }, [params.id]);

    return (
        <div>
            <SystemHeader title="Sjúklingur" />
            <div className="yfirlit">
                <PatientForm userid={params.id}/>
                <div className='sjuklingur_notkun'>
                    <UserGraf userid={params.id}/>
                </div>
            </div>
        </div>
    )
}

export default Patient