import React from 'react'

function Products() {
    return (
        <main>
            <div className="wrapper zztop">
                <h2>Vörur</h2>

                <h3>Fruma tryggir að heilbrigðisstarfsfólk veiti sjúklingum með sjaldgæfa sjúkdóma bestu þjónustu sem völ er á ásamt því að auka afkastagetu starfsfólks í heilbrigðisgeiranum.</h3>
                <h2><span>Einstaklingar</span></h2>
                <p><strong>Sjúklingar sjá allar upplýsingar um sig á einum stað með Frumu, allt frá samskiptum við heilbrigðisstarfsfólk til lyfja og sjúkrahússinnlagna. Helstu kostir Frumu eru eftirfarandi:</strong></p>
                <ul>
                    <li>Einfalt og skjótt aðgengi að fagfólki</li>
                    <li>Klæðskerasniðin heilbrigðisþjónusta á heimsmælikvarða</li>
                    <li>Aukin lífsgæði</li>
                    <li>Færri forföll úr leik eða starfi vegna sjúkdóma eða slappleika</li>
                    <li>Betri upplýsingar um heilsubætandi aðferðir</li>
                    <li>Fækkar læknisheimsóknum og óþarfa rannsóknum</li>
                    <li>Minni kostnaður - bæði fyrir sjúkling og heilbrigðiskerfið</li>

                </ul>
                <h2>Fagfólk</h2>
                <p>Fruma veitir fagfólki í heilbrigðisgeiranum aðgang að kerfi sem tryggir að allt heilbrigðisstarfsfólk starfi eftir bestu, mögulegu starfsháttum hverju sinni. Það tryggir meiri afkastagetu og betri umönnun fyrir sjúklinga. Kostir Frumu fyrir fagfólk er til að mynda eftirfarandi:</p>
                <ul>
                    <li>Styttri greiningartími sem leiðir af sér færri tilvísanir og styttri biðtími fyrir sjúklinga</li>
                    <li>Fruma getur valdið því að meðferð styttist og þar með lækkað kostnað innan heilbrigðiskerfisins</li>
                    <li>Fruma er gagnvirkt tæki sem gerir fagfólki auðveldara að sjá áhættur fyrr en ella og miðla þeim upplýsingum til sjúklinga. Þannig aukast lífsgæði sjúklinga því hver meðferð verður mun einstaklingsmiðaðri með Frumu</li>
                    <li>Færri endurkomur</li>
                    <li>Meiri stuðningur við sjúklinga</li>
                    <li>Allar tímapantanir fara í gegnum Frumu og því auðvelt að hafa yfirsýn yfir þær </li>
                    <li>Fruma velur sjálfkrafa sjúklinga sem henta í ákveðnar rannsóknir eða meðferðir</li>

                </ul>
            </div>
        </main>
    )
}

export default Products;