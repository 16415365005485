import React from 'react';
import SystemHeader from './SystemHeader';

document.body.classList.add("admin");

const Lyfjadagbok = () => {
    return (
        <div>
            <SystemHeader title="Lyfjadagbók" />
            <div className="yfirlit">
                Er að vinna í þessu!
            </div>
        </div>
    )
}

export default Lyfjadagbok