import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

import moment from "moment";
import 'moment/locale/is';

import axios from 'axios';
import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

document.body.classList.add("admin");

export class Samskipti extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get(global.config.api.url.live + '/admin_messages',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="Samskipti" />
                    <div className='notendurmsm'>
                        <ul>
                            <li><span>G</span><a href="/admin/samskipti/1">Guðmudnur Ragnar Einarsson</a></li>
                            <li><span>Þ</span><a href="/admin/samskipti/11">Þórunn Marsilía Lárusdóttir</a></li>
                            <li><span>B</span><a href="/admin/samskipti/15">Birna Jónasdóttir</a></li>
                            <li><span>E</span><a href="/admin/samskipti/17">Elín Freyja Eggertsdóttir</a></li>
                        </ul>
                    </div>
                    <div className="skilabodaskjoda">
                        {posts.map(post =>
                            <div className={"item messagetype_" + post.system} key={post.id}>
                                <div className="skilafra"><span title={fixname(post.from, post.name)} >{fixname(post.from, post.name)[0]}</span></div><div className="skilabodin">
                                    <p>{post.description}</p>
                                    <p className='skilabodtime'>{moment(post.deadline).format('Do MMM YYYY HH:mm')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="sendaadminskilabod">
                        <input type="text" placeholder="Ný skilaboð" defaultValue="" /><button className="disabled" disabled="">Senda</button>
                    </div>
                </div>
            )
        } else  {
            return ( <div>hleð...</div>)
        }
    }
}

function fixname(id,name) {
    let newname;
    if (id === 1) {
        newname = "Guðmundur Ragnar Einarsson";
    } else {
        newname = name;
    }
    return newname;
}

export default Samskipti