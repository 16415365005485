import React from 'react'
import Header from '../templates/Header';
import Footer from '../templates/Footer';

const WebSite = (props) => (
    <div>
        <script src="https://kit.fontawesome.com/731b25c641.js" crossOrigin="anonymous"></script>
        <Header/>
        {props.children}
        <Footer/>
    </div>
)

export default WebSite