import React, { Component } from 'react'
import SystemHeader from './SystemHeader';
import axios from 'axios';

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();
document.body.classList.add("admin");

export class Staff extends Component {

    state = { posts: [], isLoaded: false, staff: this.props.match.params.id }
    componentDidMount () {
        var selectedUser = "";

        if(this.props.match.params.id !== undefined) {
            selectedUser = this.props.match.params.id;
        }

        axios.get(global.config.api.url.live + '/getstaff/'+ selectedUser,{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        // staff
        const { posts } = this.state;
        if (posts) {
            console.log(posts);
        return (
            <div>
                <SystemHeader title="Starfsfólk" />
                <div className="yfirlit">
                    <form>
                            {posts.map(post =>
                            <div className='formview'>
                                <input type="hidden" name="name" value={post.id}/>
                                <label>Nafn</label>
                                <input type="text" name="name" value={post.name}/>
                                <label>Netfang</label>
                                <input type="text" name="netfang" value={post.netfang}/>
                                <label>Sími</label>
                                <input type="text" name="tel" value={post.tel}/>
                                <label>Starfsheiti</label>
                                <select name="starfsheiti" value={post.starf}>
                                    <option value="1">Admin</option>
                                    <option value="2">Læknir</option>
                                    <option value="3">Hjúkrunarfræðingur</option>
                                </select>
                            </div>
                            )}
                    </form>
                </div>
            </div>
        )
        } else {
            return (
                <div>Ekkert</div>
            )
        }
    }
}

export default Staff