import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom';

import './Header.css';

function Header() {
    const [isShown, setIsShown] = useState("closed");

    let handleChange = async () => {
        if (isShown === "closed") {
            setIsShown("open");
        } else {
            setIsShown("closed");
        }
    }

    return (
        <header className="header">
            <div className="wrapper">
                <h1 className="logo"><NavLink to="/" activeClassName="selected">Fruma</NavLink></h1>
                <div id="hamborgari" onClick={handle => {handleChange();}} className={isShown}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div id="headermenu" className={isShown}>
                    <ul className="menu">
                        <li className="btn_inn"><Link to="/login"><i className="fa-regular fa-circle-user"></i></Link></li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;