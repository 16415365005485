import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../Utils/Common';
const token = getToken();

export class HAEQualityOfLife extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/qualityoflife', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (posts[0]) {
                return (
                    <div>
                        <Topbar title="menu.lifsgaedi" heiti=""/>
                        <div className="urgencies">
                            <div className="screen">
                                <h3 className="heildarskor">Heildarskor {posts[0].score}% </h3>
                                <p className="heildarskor">Próf var tekið þann {moment(posts[0].createdon).format('Do MMM YYYY')} </p>
                                <div id="app_info">
                                    <div className='cat1'><strong className="cat1">Verkir</strong><br/>{posts[0].verkirtotal}%<br/></div>
                                    <div className='cat2'><strong>Líkamleg virkni</strong><br/>{posts[0].virknitotal}%<br/></div>
                                    <div className='cat3'><strong>Andleg líðan</strong><br/>{posts[0].andlegtotal}%<br/></div>
                                    <div className='cat4'><strong>Almenn líðan</strong><br/>{posts[0].almenntotal}%<br/></div>
                                </div>
                                <div id="app">
                                    <svg className="ActivityRings" viewBox='0 0 37 37'>
                                        <g className="ring ring1">
                                            <circle strokeWidth="3" r="15.915" cx="50%" cy="50%" className="background" />
                                            <circle strokeWidth="3" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={posts[0].verkirtotal + ",100"} />
                                        </g>
                                        <g className="ring ring2">
                                            <circle strokeWidth="4" r="15.915" cx="50%" cy="50%" className="background" />
                                            <circle strokeWidth="4" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={posts[0].virknitotal + ",100"} />
                                        </g>
                                        <g className="ring ring3">
                                            <circle strokeWidth="6" r="16.315" cx="50%" cy="50%" className="background" />
                                            <circle strokeWidth="6" r="16.315" cx="50%" cy="50%" className="completed" strokeDasharray={posts[0].andlegtotal + ",100"} />
                                        </g>
                                        <g className="ring ring4">
                                            <circle strokeWidth="9" r="16.915" cx="50%" cy="50%" className="background" />
                                            <circle strokeWidth="9" r="16.915" cx="50%" cy="50%" className="completed" strokeDasharray={posts[0].almenntotal + ",100"} />
                                        </g>
                                    </svg>

                                </div>
                                <div className="nyttprof">
                                    <a href='/notandi/hae/lifsgaedi/'>Taka próf</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <h3>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <p>Ekkert próf hefur verið tekið</p>
                        <div className="nyttprof">
                            <a href='/notandi/hae/lifsgaedi/'>Taka próf</a>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(HAEQualityOfLife);