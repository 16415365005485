import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { withTranslation} from 'react-i18next';

import { removeUserSession,getInMenu } from '../../Utils/Common';

import './Sidebar.css';

const menu = getInMenu();

export class Sidebar extends Component {
    render() {
        const {t} = this.props;

        let handleLogout = async (e) => {
            e.preventDefault();
            removeUserSession();
        }

        return (
            <div className="sidebar">
                <h1><NavLink to="/notandi/dashboard" activeClassName="selected">Fruma</NavLink></h1>
                <h4>Almennt</h4>
                <ul>
                    <li><NavLink to="/notandi/dashboard" activeClassName="selected"><i className="fa-light fa-grid-2"></i> {t('menu.yfirlit')}</NavLink></li>
                    <li><NavLink to="/notandi/samskipti" activeClassName="selected"><i className="fa-light fa-inbox"></i> {t('menu.samskipti')}</NavLink></li>
                    <li><NavLink to="/notandi/skyrsla" activeClassName="selected"><i className="fa-light fa-chart-simple"></i> {t('menu.skyrslur')}</NavLink></li>
                    <li><NavLink to="/notandi/fraedsluefni" activeClassName="selected"><i className="fa-light fa-books"></i> Fræðsluefni</NavLink></li>
                </ul>
                {showinmenu(menu,1) &&
                <h4>HAEXpert</h4>
                }
                {showinmenu(menu,1) &&
                <ul>
                    <li><NavLink to="/notandi/HAExpert" activeClassName="selected"><i className="fa-regular fa-monitor-waveform"></i> {t('menu.haexpert')}</NavLink></li>
                </ul>
                }
                {showinmenu(menu,4) &&
                <h4>IgXpert</h4>
                }
                {showinmenu(menu,4) &&
                <ul>
                    <li><NavLink to="/notandi/IgExpert/" activeClassName="selected"><i className="fa-regular fa-pills"></i> {t('menu.igexpert')}</NavLink></li>
                    <li><NavLink to="/notandi/antibodydonations/" activeClassName="selected"><i className="fa-regular fa-syringe"></i> {t('menu.medicine_reminder')}</NavLink></li>
                </ul>
                }
                {showinmenu(menu,2) &&
                <h4>{t('menu.lifsgaedi')}</h4>
                }
                {showinmenu(menu,2) &&
                <ul>
                    <li><NavLink to="/notandi/hae/lifsgaedi/" activeClassName="selected"><i className="fa-sharp fa-regular fa-heart-pulse"></i> {t('menu.lifsgaedi')}</NavLink></li>
                </ul>
                }
                <ul>
                    <li><NavLink to="/notandi/minsida" activeClassName="selected"><i className="fa-light fa-user"></i> {t('menu.mypage')}</NavLink></li>
                    <li><button onClick={handleLogout}><i className="fa-solid fa-power-off"></i> {t('menu.logout')}</button></li>
                </ul>

                <ul className="actionBtn">
                    <div className="logingout"></div>
                </ul>
            </div>
        )
    }
}

function showinmenu(menu,item) {
    if (menu === null) {
        return false;
    } else {
        let result = menu.includes(item);
        return result;
    }
}

export default withTranslation()(Sidebar);