import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import { Link } from 'react-router-dom';
export class EducationalMaterial extends Component {
    render() {
        return (
            <div>
                <Topbar title="Fræðsluefni" heiti=""/>
                <main>
                    <div className='fraeefnid'>
                        <h1>Hvað eru mótefni?</h1>
                        <p>Mótefni eru prótín sem eru mikilvægur hluti ónæmiskerfis líkamans. Helsta hlutverk mótefna er að vernda okkur gegn innrás sýkla og móta ónæmissvar líkamans. Þau eru framleidd af svokölluðum B eitilfrumum, að stærstum hluta í beinmerg, eitlum og meltingarvegi. </p>
                        <h2>Flokkar mótefna og hlutverk</h2>
                        <p><strong>Meginflokkar mótefna eru fimm:</strong></p>
                        <ol>
                            <li>IgM er fyrsta mótefnið sem framleitt er í sýkingu eða eftir fyrstu bólusetningu. Við endurteknar sýkingar verður til ónæmisminni þar sem hinir flokkarnir (IgA, IgG, IgE og IgD) taka yfirhöndina.</li>
                            <li>IgA er aðalvörn okkar á yfirborði slímhúða. Líkaminn framleiðir mest af IgA mótefnum.</li>
                            <li>IgG er algengasta mótefnið í blóði. Hlutverk þess er að vinna gegn skaðlegum efnum og sýklum og einnig að virkja aðrar frumur ónæmiskerfisins. Það flyst yfir í fylgju og til barns á meðgöngu. IgG er mjög mikilvægt í baráttu gegn bakteríum og veirum.</li>
                            <li>IgE tekur þátt í útrýmingu sníkla og frumdýra. Það hefur einnig hlutverk í viðbrögðum líkamans í ofnæmi.</li>
                            <li>IgD en hlutverk þess er ekki að fullu ljóst. Þó er vitað að það hefur hlutverki að gegna við stjórnun bólgusvars.</li>
                        </ol>
                        <h2>Mótefni í lyfjaformi</h2>
                        <p>Mótefni sem gefin eru í lyfjaformi innihalda nær eingöngu mótefni af IgG gerð. Þau gagnast því vel þeim sem eru með staðfestan IgG skort. Regluleg IgG mótefnagjöf getur dregið verulega úr líkum á sýkingum, sérstaklega bakteríu- og veirusýkingum. Auk þess flýtir það fyrir að losa líkamann við sýkingar sem þegar hafa myndast. Þannig tekur skemmri tíma en ella að ráða við sýkingu. Þörf fyrir aðra lyfjanotkun og aðra meðferð getur einnig minnkað samhliða mótefnagjöf. Þar sem IgG mótefnin eru ekki eina vörn okkar gegn sýkingum er ekki mögulegt að koma í veg fyrir allar sýkingar með mótefnagjöf.</p>
                        <p>Eins og gildir um önnur prótín þá eyðist IgG úr líkamanum með tímanum. Við meðfæddum eða áunnum IgG skorti þarf því að gefa lyfið reglulega, á 3-4 vikna fresti í æð eða vikulega undir húð.</p>
                        <h2>Frekari upplýsingar</h2>
                        <p>Á <a href="https://www.landspitali.is/sjuklingar-adstandendur/deildir-og-thjonusta/gongudeild-onaemisfraedideildar/">heimasíðu ónæmisfræðideildar Landspítala</a> er að finna nánari upplýsingar.</p>

                        <Link to="/notandi/fraedsluefni" className='lesameira'>Til baka í <strong>Fræðsluefni</strong></Link>
                    </div>
                </main>
            </div>
        )
    }
}

export default EducationalMaterial;