import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import { Link } from 'react-router-dom';
export class EducationalMaterial extends Component {
    render() {
        return (
            <div>
                <Topbar title="Fræðsluefni" heiti=""/>
                <main>
                    <div className='fraeefnid'>
                        <h1>Hvað get ég gert til að styrkja ónæmiskerfið?</h1>
                        <h4>Sjúklingafræðsla | Ágúst 2022</h4>
                        <p>Ónæmiskerfið er lykill að vörnum okkar gegn sýklum og öðrum sjúkdómsvöldum. Frumur og líffæri ónæmis­ kerfisins eru stöðugt að taka til í líkamanum, losa hann við frumur sem geta myndað krabbamein, dauðan vef og slökkva á bólgu, jafnframt því að viðhalda heilbrigði og hæfni ónæmiskerfisins. Þó svo að arfgerð ráði miklu, þá vega hegðun okkar, athafnir og fæðuval einnig þungt í kraftmiklu ónæmissvari (mynd 1).</p>
                        <center><img src="/img/mynd1.png" className='responsiveimg' alt="Hvað get ég gert til að styrkja ónæmiskerfið?"/></center>
                        <h2>Fjölbreytt fæða</h2>
                        <p>Með því að neyta fjölbreyttrar fæðu fást þau næringarefni sem líkaminn þarf á að halda og grunnur er lagður að góðri heilsu. Fólki með meðfæddan eða áunninn ónæmisgalla er ráðlagt að forðast hráa eða létt eldaða rétti úr kjöti, fiski eða eggjum og sneiða hjá ógerilsneyddum mjólkurvörum. Einnig skiptir máli að skola ávexti og grænmeti vandlega fyrir neyslu.</p>
                        <h2>Heilbrigð meltingarflóra</h2>
                        <p>Heilbrigð meltingarflóra skiptir máli fyrir starfsemi ónæmiskerfisins. Ofnotkun sýklalyfja, sýklalyf í matvælum, einhæft fæðuval o.fl. vinnur gegn eðlilegri meltingarflóru. Trefjarík fæða styrkir heilbrigða meltingarflóru, einnig fæða sem hefur farið í gegnum náttúrulega gerjun eins og t.d. jógúrt og súrkál. Með því að huga að heilbrigðri meltingarflóru er hægt að styrkja slímhúðina og ónæmiskerfið gegn innrás skaðlegra baktería og veira. Samtímis inntöku sýklalyfja er mælt með að taka góðgerla, svo sem AB mjólk eða asídófílus.</p>
                        <h2>Nauðsynleg vítamín, snefil- og steinefni</h2>
                        <p>Ýmis vítamín, snefil­ og steinefni svo sem sink, selen, járn, C­vítamín, B­vítamín, D­vítamín, E­vítamín og A­vítamín skipta máli fyrir starfsemi ónæmiskerfisins. Bætiefni eru oftast óþörf en til þess að fá nóg D­vítamín yfir vetrarmánuðina á Íslandi er nauðsynlegt að taka inn D­vítamín sérstaklega sem bætiefni, annaðhvort lýsi, D­vítamíntöflur eða ­úða. Önnur næringarefni er hægt að fá í hæfilegum skömmtum með því að neyta fjölbreyttrar fæðu. Ef til staðar eru vandamál svo sem lystarleysi, fæðuóþol eða ­ofnæmi gæti þurft að taka þau inn í töfluformi.</p>
                        <h3>Snefil- og steinefni:</h3>
                        <ul>
                            <li>Sink skiptir máli fyrir starfsemi slímhúðarinnar. Dæmi um fæðu sem inniheldur sink er gróft korn, hnetur, fræ og kjöt.</li>
                            <li>Selen er andoxunarefni og skiptir máli fyrir heilbrigt ónæmissvar. Dæmi um fæðu sem inniheldur selen er sólblómafræ, haframjöl, egg, baunir og lax.</li>
                            <li>Járn er nauðsynlegt fyrir eðlilega blóðmyndun, heilbrigði slímhúðarinnar og frumur ónæmiskerfisins. Dæmi um járnríka fæðu er kjöt, spínat, hörfræ og hafragrjón.</li>
                        </ul>
                        <h3>Vatnsleysanleg vítamín (safnast ekki fyrir í líkamanum):</h3>
                        <ul>
                            <li>C ­vítamín stuðlar að aukinni getu líkamans til að vinna járn úr fæðunni og er mikilvægt fyrir myndun á sumum frumum ónæmiskerfisins. C­vítamín skiptir einnig máli fyrir ónæmiskerfi húðarinnar og vinnur gegn frumuskemmdum og bólgu. Dæmi um C­vítamínríka fæðu er sítrusávextir, tómatar, jarðarber og grænt grænmeti.</li>
                            <li>B ­vítamín eru 8 talsins og hafa margvísleg áhrif á starfsemi ónæmiskerfisins og slímhúðarinnar. B6­ og B12­vítamín eru sérstaklega mikilvæg. B12­vítamín er t.d. í eggjum, fiskmeti og kjöti. B6­vítamín er t.d. í hnetum, túnfiski og kjúklingabaunum.</li>
                        </ul>
                        <h3>Fituleysanleg vítamín (safnast fyrir í líkamanum, ofneysla getur orsakað eitrun):</h3>
                        <ul>
                            <li>A ­vítamín hefur umtalsverð áhrif á starfshæfni ónæmiskerfisins, sérstaklega virkni eitilfruma sem drepa t.d. veirusýktar frumur. Það hefur einnig áhrif á bólguvirkni og styrkir getu ónæmiskerfisins til að ráða niðurlögum sýkinga. Dæmi um A­vítamínríka fæðu er fiskur, egg, hnetur, gulir eða dökkgrænir ávextir og grænmeti svo sem gulrætur, paprika og spergilkál.</li>
                            <li>E ­vítamín er mikilvægt andoxunarefni í líkamanum. Skortur á því er mjög sjaldgæfur. Dæmi um E­vítamínríka fæðu er jurtaolía, egg, smjör, hnetur og fræ, fiskur og kjöt.</li>
                            <li>D ­vítamín skortur skerðir varnir líkamans gegn sýkingum, þ.m.t. kvefpestum og öndunarfæra­ sýkingum. Dæmi um D­vítamínríka fæðu er lýsi, fiskur og D­vítamínbætt mjólk. Erfitt er að uppfylla þörf fyrir vítamínið með fæðu einni saman og því er mikilvægt að taka D­vítamín sem bætiefni, annað hvort Lýsi, D­vítamíntöflur eða ­úða. D­vítamín myndast í húðinni fyrir tilstilli útfjólublárra geisla sólar og því hefur útivist í sól einnig góð áhrif á D­vítamínbúskap.</li>
                        </ul>

                        <div className='greininfo'>
                            <h3>Ráðlagður dagskammtur fyrir D-vítamín:</h3>
                            <ul>
                                <li>15 míkrógrömm (μg) (600 AE) fyrir aldurshópinn 10 til 70 ára</li>
                                <li>20 μg (800 AE) fyrir fólk yfir sjötugt</li>
                                <li>Börn yngri en 10 ára og ungbörn er 10 μg (400 AE) á dag</li>
                            </ul>
                        </div>

                        <h2>Vökvainntekt</h2>
                        <p>Frumur líkamans þurfa á vatni að halda til að viðhalda góðri líkamsstarfsemi og því þurfum við að drekka nægan vökva yfir daginn. Vatnið flytur næringarefni og súrefni inn í frumur, styrkir húð og slímhúðir, hjálpar til við efnaskipti í líkamanum og kemur jafnvægi á líkamshita svo dæmi séu nefnd. Almennt er miðað við að fullorðinn einstaklingur drekki u.þ.b. 1,5–2 lítra af vökva á dag. Þar er með talinn allur vökvi þ.e. súpur, ýmsir drykkir o.fl. sem innihalda vökva. Þetta þýðir að flestir þurfa að bæta við 2–3 vatnsglösum á dag til að uppfylla vökvaþörfina, það getur þó verið einstaklingsbundið. Í miklum hita eða við áreynslu þarf að drekka meira. Íslenska vatnið er heilnæmt í allflestum landshlutum en einstaklingar með ónæmisgalla ættu að forðast að drekka kranavatn á ferðalögum erlendis.</p>
                        <h2>Álag og streita</h2>
                        <p>Fjölmargar rannsóknir hafa sýnt fram á að streita og kvíði dragi úr starfshæfni ónæmiskerfisins. Kemur það fram í aukinni tíðni sýkinga sérstaklega í öndunarfærum og aukinni bólgumyndun. Því þarf að hlúa að andlegri heilsu og sækja stuðning og aðstoð ef á þarf að halda.</p>
                        <h2>Svefn og svefnvenjur</h2>
                        <p>Svefninn er nauðsynlegur fyrir líkamlega og andlega heilsu okkar. Í svefni gefst færi á að skapa aftur jafnvægi í líkamsstarfseminni eftir erfiði og amstur liðins dags. Þá fær ónæmiskerfið næði til að taka til, hreinsa dauðar frumur, skaddaðan vef og bólguefni úr umhverfinu, auk þess að styrkja eigin varnarkerfi og slímhúðir gegn utanaðkomandi hættum. Ráðlagt er að hafa reglu á svefntímum og sofa að lágmarki 6-8 klukkustundir á nóttu. Einnig er gott að hafa í huga að skjánotkun uppi í rúmi fyrir svefn, áfengi og koffíndrykkir á kvöldin geta haft slæm áhrif á svefninn.</p>
                        <h2>Áfengisnotkun</h2>
                        <p>Áfengi og niðurbrotsefni þess eru skaðleg nær öllum frumum líkamans og er ónæmiskerfið þar ekki undanskilið. Mikil áfengisneysla eykur verulega tíðni og alvarleika öndunarfærasjúkdóma eins og kvefs og lungnabólgu, auk þess hægist verulega á bata og getu ónæmiskerfisins til að gera við skemmdir.</p>
                        <h2>Reykingar</h2>
                        <p>Reykingar hafa slæm áhrif á slímhúð öndunarfæranna og getu ónæmiskerfisins til að verja okkur gegn utanaðkomandi hættum. Það sést t.d. með aukinni tíðni öndunarfærasýkinga. Það er því mikill ávinningur fólginn í því að hætta að reykja.</p>
                        <p>Í bæklingnum <a href="https://www.landlaeknir.is/servlet/file/store93/item11972/haettu_fyrirlifid.pdf" target='_blank' rel='noreferrer'>„Hættu fyrir lífið“</a> eru upplýsingar fyrir þá sem eru að hugsa um að hætta að reykja.</p>
                        <p>Einnig er hægt að leita aðstoðar á heilsugæslustöðvum og á <a href="https://www.heilsuvera.is/" target='_blank' rel="noreferrer" alt="Tengill á Heilsuveru">www.heilsuvera.is</a> (netspjall, símaráðgjöf og fræðsluefni).</p>

                        <h2>Hreyfing</h2>
                        <p>Hreyfing, sérstaklega þolþjálfun, hefur jákvæð áhrif á andlega og líkamlega heilsu. Til að ná fram jákvæðum áhrifum þolþjálfunar þarf ekki að hreyfa sig meira en 20–30 mín tvisvar til þrisvar í viku. Fyrir þá sem eru að taka sín fyrstu skref í hreyfingu, þá er best að fara rólega af stað og auka þjálfun hægt og rólega. Þeir sem eru með langvinna sjúkdóma ættu að hafa samband við sinn lækni áður en lagt er af stað.</p>
                        <ul>
                            <li>Regluleg hreyfing styrkir færni og getu ónæmiskerfisins til að verjast sýkingum og dregur úr bólgumyndun í líkamanum. Hreyfing er ekki bara góð fyrir ónæmiskerfið, hún bætir einnig andlega líðan, svefn og minnkar kvíða.</li>
                            <li>Vænlegast til árangurs er að finna hreyfingu sem hentar og viðkomandi hefur gaman að. Dæmi um góða leið til að bæta þol og styrk er t.d. kraftganga, fjallganga, gönguskíði, sund, hjólreiðar, dans og skokk.</li>
                            <li>Þeir sem eru með mikið slím í lungum geta leitað til sjúkraþjálfara til að fá ráðleggingar um aðferðir til þess að losna við slím.</li>
                            <li>Óhóflegar æfingar sem stundaðar eru af mikilli ákefð geta haft öfug áhrif á heilsu okkar og ónæmissvar.</li>
                            <li>Hiti og önnur einkenni sýkinga eru tilefni til að sleppa úr æfingu.</li>
                        </ul>

                        <div className='greininfo'>
                            <h3>Ýmislegt er hægt að gera til að styrkja ónæmiskerfið:</h3>
                            <ul>
                                <li>Borða fjölbreytta fæðu</li>
                                <li>Huga að heilbrigðri meltingarflóru</li>
                                <li>Taka D-vítamín sem bætiefni yfir vetrartímann</li>
                                <li>Drekka nægan vökva</li>
                                <li>Draga úr álagi og streitu</li>
                                <li>Huga að svefni og hvíld</li>
                                <li>Takmarka neyslu áfengis og reykingar</li>
                                <li>Hreyfa sig reglulega</li>
                            </ul>
                        </div>
                        <p>Frekari upplýsingar eru á <a href="https://www.landspitali.is/sjuklingar-adstandendur/deildir-og-thjonusta/gongudeild-onaemisfraedideildar/" target='_blank' rel="noreferrer" alt="Göngudeild ónæmisfræðideildar">heimasíðu ónæmisfræðideildar Landspítala</a></p>

                        <Link to="/notandi/fraedsluefni" className='lesameira'>Til baka í <strong>Fræðsluefni</strong></Link>
                    </div>
                </main>
            </div>
        )
    }
}

export default EducationalMaterial;