import React, { Component } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NewUserGrafMonth from './user/components/NewUserGrafMonth';
import Qol from './user/components/Qol';
import { withTranslation } from 'react-i18next';
import Topbar from '../templates/Topbar';
import { getInMenu } from '../../Utils/Common';

import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../Utils/Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

const menu = getInMenu();

export class Skyrsla extends Component {
    state = { posts: [], isLoaded: false, userid: null,count:6 };

    componentDidMount () {
        axios.get(global.config.api.url.live + '/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ userid: res.data.data.userid, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    handleChange = (e) => {
        this.setState({ count: e.target.value });
    };

    render() {
        const { t } = this.props;
        const { isLoaded, userid, count} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.skyrsla" heiti="" />
                    <main>
                        <div className='filter'>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tímabil</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={count}
                                    label="Tímabil"
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem value={3}>3 mánuðir</MenuItem>
                                    <MenuItem value={6}>6 mánuðir</MenuItem>
                                    <MenuItem value={9}>9 mánuðir</MenuItem>
                                    <MenuItem value={12}>1 ár</MenuItem>
                                    <MenuItem value={24}>2 ár</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        {showinmenu(menu,1) &&
                        <div className="yfirlit">
                            <h3 className='section_heading'><Link to="/notandi/HAExpert">{t('menu.haexpert')}</Link></h3>
                            <NewUserGrafMonth userid={userid} count={count}/>
                        </div>
                        }
                        {showinmenu(menu,2) &&
                        <div className="yfirlit">
                            <h3 className='section_heading'><Link to="/notandi/hae/lifsgaedi/">{t('menu.lifsgaedi')}</Link></h3>
                            <h4><strong>Tilfelli</strong></h4>
                            <Qol/>
                        </div>
                        }
                        {showinmenu(menu,4) &&
                        <div className="yfirlit">
                            <h3 className='section_heading'><Link to="/notandi/IgExpert/">{t('menu.igexpert')}</Link></h3>
                            <h4><strong>Tilfelli</strong> - Síðustu {count} mánuði</h4>
                        </div>
                        }
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    <Topbar title="menu.skyrsla" heiti="" />
                    <main>
                        <div className="yfirlit"></div>
                    </main>
                </div>
            )
        }
    }
}

function showinmenu(menu,item) {
    if (menu === null) {
        return false;
    } else {
        let result = menu.includes(item);
        return result;
    }
}

export default withTranslation()(Skyrsla);