import React from 'react'
import Sidebar from '../templates/Sidebar';

const UserSystem = (props) => (
    <div>
        <div className="backpeace">
            <div className="kulaeitt"></div>
            <div className="kulatvo"></div>
        </div>
        <Sidebar/>
        <div className='admin-system'>
            {props.children}
        </div>
    </div>
)

export default UserSystem