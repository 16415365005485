import React, { Component } from 'react';
import axios from 'axios';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken } from '../../../../Utils/Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAEQualityOfLife extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/qualityoflife/all/', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        console.log(posts);
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <main>
                            <table className='qolbars'>
                                <tbody>
                                <tr>
                                {posts.map(post =>
                                    <td key={post.id}><div className={"qolbar " + getColor(post.score)} style={{"height": post.score + "px"}}><span>{post.score}</span></div><div className='qolbottom'>{moment(post.createdon).format('Do MMM YY')}</div></td>
                                )}
                                </tr>
                                </tbody>
                            </table>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <p>Ekkert próf hefur verið tekið</p>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getColor(p) {
    let mycolor = "litur_4";
    
    if (p === 100) {
        mycolor = "litur_0";
    } else if (p > 0 && p <= 24) {
        mycolor = "litur_1";
    } else if (p >= 25 && p <= 49) {
        mycolor = "litur_2";
    } else if (p >= 50 && p <= 74) {
        mycolor = "litur_3";
    } else if (p >= 75) {
        mycolor = "litur_4";
    }

    return mycolor;
}

export default HAEQualityOfLife;