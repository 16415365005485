import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import { withTranslation} from 'react-i18next';

import './AddUrgency.css';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../Utils/Common';
const token = getToken();

export class AddAntibodyDonations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            isLoaded: false,
            perpage: 0,
            checked: false,
            injectiondate: new Date(),
            batchNumber:"",
            medicines:"",
            sideeffects:"",
            infections:0,
            infectionsdate: new Date(),
            fever:"37,7",
            symptoms:"",
            antibiotics:"",
            absences:0,
            absencestype: 1
        }

        this.setValue = this.setValue.bind(this);
        this.setBatchNumber = this.setBatchNumber.bind(this);
        this.setMedicines = this.setMedicines.bind(this);
        this.setSideeffects = this.setSideeffects.bind(this);

        this.setInfectionsdate = this.setInfectionsdate.bind(this);
        this.setFever = this.setFever.bind(this);
        this.setSymptoms = this.setSymptoms.bind(this);
        this.setAntibiotics = this.setAntibiotics.bind(this);
        this.setAbsences = this.setAbsences.bind(this);

    }

    setValue(p) { this.setState({injectiondate: p}); }
    setBatchNumber(p) { this.setState({batchNumber: p.target.value}); }
    setMedicines(p) { this.setState({medicines: p.target.value}); }
    setSideeffects(p) { this.setState({sideeffects: p.target.value}); }

    setInfectionsdate(p) { this.setState({infectionsdate: p}); }
    setFever(p) { this.setState({fever: p.target.value}); }
    setSymptoms(p) { this.setState({symptoms: p.target.value}); }
    setAntibiotics(p) { this.setState({antibiotics: p.target.value}); }
    setAbsences(p) { this.setState({absences: p.target.value}); }

    render() {
        // absencestype
        const { checked, injectiondate,batchNumber,medicines,sideeffects,infections, infectionsdate, fever, symptoms, antibiotics, absences} = this.state;

        const handleChange = (e) => {
            if (checked === false) {
                this.setState({ checked: true })
            } else {
                this.setState({ checked: false })
            }
        };

        const handleSubmit = event => {
            event.preventDefault();
            let dagurinn = moment(injectiondate).format('yyyy-MM-DD');
            const jsonbody = JSON.stringify({
                injectiondate: dagurinn,
                batchNumber: batchNumber,
                medicines: medicines,
                sideeffects: sideeffects,
                infections: infections,
                infectionsdate: dagurinn,
                fever: fever,
                symptoms: symptoms,
                antibiotics: antibiotics,
                absences: absences
            });
            const customConfig = {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }, crossdomain: true
            };

            axios.post(global.config.api.url.live + '/abd', jsonbody, customConfig )
            .then(res => {
                    if (res.data.statusCode === 201) {
                        window.location = "/notandi/antibodydonations/";
                    } else {
                        console.log("Villa: " + res.data)
                    }
                }
            )
            .catch(error => {
                const response = error.response;
                console.log("Villa: " + response.data);
            })
        };

        return (
            <div>
                <Topbar title='menu.medicine_reminder' heiti=""/>
                <main>
                    <div className='seventycent'>
                        <h3 className='dagbok'>Mótefnagjöf:</h3>
                        <table className='motefnagjof'>
                            <tbody>
                                <tr>
                                    <td className='dagseting_mot'>Dagsetning</td>
                                    <td className='dagseting_mot'><DateTimePicker  onChange={(e) => this.setValue(e)} value={injectiondate} format="y-MM-dd" disableClock={null} /></td>
                                </tr>
                                <tr>
                                    <td>Lotunúmer og lyfjaskammtur</td>
                                    <td><input type='text' id="batchNumber" onChange={(e) => this.setBatchNumber(e)} value={batchNumber}/></td>
                                </tr>
                                <tr>
                                    <td>Lyf tengd lyfjagjöf</td>
                                    <td><input type='text' id="medicines" onChange={(e) => this.setMedicines(e)} value={medicines}/></td>
                                </tr>
                                <tr>
                                    <td>Aukaverkanir</td>
                                    <td><input type='text' id="sideeffects" onChange={(e) => this.setSideeffects(e)} value={sideeffects}/></td>
                                </tr>
                            </tbody>
                        </table>
                        <p><strong>Hefur þú fengið sýkingar síðastliðna viku?</strong> <input type="checkbox" className='showcheck' id="infections" defaultChecked={checked} onClick={handleChange} /><br/>Ef svarið er <strong>Já</strong> skráir þú hér fyrir neðan:</p>
                        <table className={'motefnagjof is' + checked}>
                            <tbody>
                                <tr>
                                    <td className='dagseting_mot'>Dagsetning</td>
                                    <td className='dagseting_mot'><DateTimePicker onChange={(e) => this.setInfectionsdate(e)} value={infectionsdate} format="y-MM-dd" disableClock={null} /></td>
                                </tr>
                                <tr>
                                    <td>Hiti (°C)</td>
                                    <td className='small_mot'><input type='text' id="fever"  onChange={(e) => this.setFever(e)} value={fever} /></td>
                                </tr>
                                <tr>
                                    <td>Einkenni sýkingar</td>
                                    <td><input type='text' id="symptoms" onChange={(e) => this.setSymptoms(e)} value={symptoms} /></td>
                                </tr>
                                <tr>
                                    <td>Sýklalyf</td>
                                    <td><input type='text' id="antibiotics" onChange={(e) => this.setAntibiotics(e)} value={antibiotics} /></td>
                                </tr>
                                <tr>
                                    <td>Fjarvistir (dagar)</td>
                                    <td className='small_mot'><input type='text' id="absences" onChange={(e) => this.setAbsences(e)} value={absences} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="nyttprof"><button type="submit" className="buatilnotenda" onClick={handleSubmit}>Skrá</button></div>
                    </div><div className='thirtyycent'>
                        <h2 className='dagbok'>Skrá mótefnagjöf</h2>
                        <p className='lysing'>Þessi dagbók er fyrir þig sem ert í mótefnameðferð í heimahúsi. Hér skráir þú sjálf/ur mótefnagjafir, hvernig þér líður eftir gjöfina og hvernig líðan þín er á milli gjafa. Tilgangur skráningar er gefa betri sýn á eigið heilsufar. Þetta gagnast bæði þér og fagfólki til að meta árangur meðferðar.</p>
                        <p>Sjá leiðbeningar <a href="/notandi/fraedsluefni/dagbok-fyrir-motefni">hér</a></p>
                        <p className='lysing'><strong>Það er mikilvægt að þú skráir lotunúmer á lyfjaglasi við hverja mótefnagjöf.</strong></p>
                    </div>
                </main>
            </div>
        )
    }
}


export default withTranslation()(AddAntibodyDonations);