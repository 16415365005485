import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import { withTranslation} from 'react-i18next';

export class Journal extends Component {
    render() {
        const { t } = this.props;
        
        return (
            <div>
                <Topbar title={t('menu.journal')} heiti=""/>
                <main>

                </main>
            </div>
        )
    }
}

export default withTranslation()(Journal);