import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import './Sidebar.css';
import { withTranslation} from 'react-i18next';
import { getInMenu } from '../../Utils/Common';

const menu = getInMenu();

export class MobileHeader extends Component {

    state = {
        isShown: "closed",
        addShow: "closed"
    };

    render() {

        const { t } = this.props;

        let handleChange = async () => {
            if (this.state.isShown === "closed") {
                this.setState({isShown: "open"});
                this.setState({addShow: "closed"});
            } else {
                this.setState({isShown: "closed"});
            }
        }

        let handleAddChange = async () => {
            if (this.state.addShow === "closed") {
                this.setState({addShow: "open"});
                this.setState({isShown: "closed"});
            } else {
                this.setState({addShow: "closed"});
            }
        }

        return (
            <div>
                <div id="hamborgari" onClick={handle => {handleChange();}} className={this.state.isShown}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div id="menu" className={this.state.isShown}>
                    <MobileMenu/>
                </div>
                <div className="mobile-header"><h1 className="logo"><NavLink to="/notandi/dashboard" activeClassName="selected"><span></span></NavLink></h1></div>
                <div className={this.state.addShow + " mobileaddval"}>
                    {showinmenu(menu,1) &&
                    <ul>
                        <li><NavLink to="/notandi/HAExpert" activeClassName="selected"><i className="fa-regular fa-monitor-waveform"></i> {t('menu.haexpert')}</NavLink></li>
                    </ul>
                    }
                    {showinmenu(menu,4) &&
                        <ul>
                            <li><NavLink to="/notandi/IgExpert/" activeClassName="selected"><i className="fa-regular fa-pills"></i> {t('menu.igexpert')}</NavLink></li>
                        </ul>
                    }
                    {showinmenu(menu,3) &&
                        <ul>
                            <li><NavLink to="/notandi/antibodydonations/" activeClassName="selected"><i className="fa-regular fa-syringe"></i> {t('menu.medicine_reminder')}</NavLink></li>
                        </ul>
                    }
                    {showinmenu(menu,2) &&
                        <ul>
                            <li><NavLink to="/notandi/hae/lifsgaedi/" activeClassName="selected"><i className="fa-sharp fa-regular fa-heart-pulse"></i> {t('menu.lifsgaedi')}</NavLink></li>
                        </ul>
                    }
                </div>
                <div className='mobile-footer'>
                    <div className='menuitem'>
                        <NavLink to="/notandi/dashboard" activeClassName="selected"><i className="fa-light fa-grid-2"></i><br/> {t('menu.yfirlit')}</NavLink>
                    </div><div className='menuitem'>
                        <NavLink to="/notandi/skyrsla" activeClassName="selected"><i className="fa-light fa-chart-simple"></i><br/> {t('menu.skyrslur')}</NavLink>
                    </div><div className='menuitem'>
                        <button  onClick={handle => {handleAddChange();}} className={this.state.addShow + " mobilemenubtn"}><i className="fa-solid fa-circle-plus"></i></button>
                    </div><div className='menuitem'>
                        <NavLink to="/notandi/samskipti" activeClassName="selected"><i className="fa-light fa-inbox"></i><br/> {t('menu.samskipti')}</NavLink>
                    </div><div className='menuitem'>
                        <NavLink to="/notandi/fraedsluefni" activeClassName="selected"><i className="fa-light fa-books"></i><br/> Fræðsluefni</NavLink>
                    </div>
                </div>
            </div>
            )

    }
}

function showinmenu(menu,item) {
    if (menu === null) {
        return false;
    } else {
        let result = menu.includes(item);
        return result;
    }
}

export default withTranslation()(MobileHeader);