import React from 'react'
import Topbar from '../templates/Topbar';

const MedicineReminder = () => {

    return (
        <div>
            <Topbar title='menu.medicine_reminder' heiti=""/>
            <main>
                <div className='seventycent'>
                </div><div className='thirtyycent'>
                    <a href="/notandi/antibodydonations">Antibody donations / Mótefnagjafir</a>
                </div>
            </main>
        </div>
    )
}

export default MedicineReminder;