import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import SystemHeader from './SystemHeader';
import UserDropdown from './components/UserDropdown';

import moment from "moment";
import 'moment/locale/is';
//decrypt,encrypt
import { decrypt } from '../../helpers/decrypt'

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAExpert extends Component {

    state = { posts: [], isLoaded: false, perpage: 0, selected: this.props.match.params.userid }
    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        var selectedUser = "";

        if(this.props.match.params.userid !== undefined) {
            selectedUser = "/" + this.props.match.params.userid;
        }

        axios.get(global.config.api.url.live + '/urgenciesadmin' + selectedUser, {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded, selected} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="menu.haexpert"/>
                    <main>
                        <UserDropdown url="hae" selected={selected}/>
                        <table className='qualityoflife'>
                            <thead>
                                <tr>
                                    <th colSpan="2">Kast</th>
                                    <th>Sjúklingur</th>
                                    <th>Skráð</th>
                                    <th>Ráðgjöf</th>
                                    <th>Ástæða</th>
                                    <th>Staðsetningar</th>
                                    <th class="tc">Staða</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {posts.map(post =>
                                <tr key={post.id}>
                                    <td width="1%"><div className='heildarskor_mynd'><i className={getIcon(post.ended)}></i></div></td>
                                    <td><Link to={"/admin/haeview/"+post.id} className={"kast_"+post.hex}>{fixEndedName(post.ended)}</Link></td>
                                    <td><Link to={"/admin/patients/" + post.userid} >{decrypt(post.fullname)}</Link></td>
                                    <td>{moment(post.registered).format('Do MMM YYYY')}</td>
                                    <td>{post.reaction}</td>
                                    <td>{post.cause}</td>
                                    <td>{post.where}</td>
                                    <td class="tc">{athugaStodu(post.status, post.id, t('open'), t('closed') )}</td>
                                    <td className='tl'><a href={"/admin/haeview/"+post.id} className="skodamat">Skoða</a></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getIcon(ended) {
    let heildarskorIcon = "";
    if (ended === "Alvarleg") {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (ended === "Miðlungs") {
        heildarskorIcon = "fa-regular fa-face-anguished";
    } else if (ended === "Óveruleg") {
        heildarskorIcon = "fa-regular fa-face-expressionless";
    } else if (ended === "Væg") {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}

function fixEndedName(ended) {
    let newname = "";
    if (ended === "Alvarleg") {
        newname = "Alvarlegt";
    } else if (ended === "Miðlungs") {
        newname = "Miðlungs";
    } else if (ended === "Óveruleg") {
        newname = "Óverulegt";
    } else if (ended === "Væg") {
        newname = "Vægt";
    }
    return newname;
}

function athugaStodu(p,id) {
    if (p === 0) {
        let thisIsMyCopy = "<span>Lokað kast</span>"
        return <small className='opid' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></small>
    } else {
        let thisIsMyCopy = "<span>Opið kast</span>";
        return <small className='lokad' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></small>
    }
}

export default withTranslation()(HAExpert);