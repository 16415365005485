import React, { Component } from 'react'
import axios from 'axios';
import { getToken } from  '../../../../Utils/admincommon'
import { decrypt } from '../../../helpers/decrypt'
const token = getToken();


export class UserDropdown extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get(global.config.api.url.live + '/getusers',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        function handleChange(value) {
            window.location.href = value;
        }

        const { posts } = this.state;
        return (
            <select onChange={event => handleChange(event.target.value)} className='userlist' value={"/admin/"+ this.props.url +"/"+this.props.selected}>
                <option value={"/admin/"+this.props.url}>Allir sjúklingar</option>
                {posts.map(post => {
                       //if(parseInt(post.id) === parseInt(this.props.selected)) {
                        //    return <option value={"/admin/"+ this.props.url +"/" + post.id} key={post.id}>{decrypt(post.name)}</option>
                        //} else {
                            return <option value={"/admin/"+ this.props.url +"/" + post.id} key={post.id}>{decrypt(post.name)}</option>
                        //}
                    }
                )}
            </select>
        )
    }
}

export default UserDropdown;