import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../../../Utils/Common';
const token = getToken();

export class Appointments extends Component {

    state = {
        posts: [],
        isLoaded: false,
        rowsreturned: 0
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/appointments', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.appointments, isLoaded: true, rowsreturned: res.data.data.rows_returned }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded, rowsreturned} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (rowsreturned === 0) {
                return (
                    <div>
                        <h3>{t('home.appointments')} <Link to="/notandi/timapantanir"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <div className='appointments'>
                            Engin tími skráður
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <h3>{t('home.appointments')} <Link to="/notandi/timapantanir"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <div className='appointments'>
                        {posts.map(post =>
                            <div className='appointment' key={post.id}>
                                <div className='doctor'>
                                    <div className="name">{post.staff}</div>
                                    <div className='job'>{post.job}</div>
                                </div>
                                <div className='time'>{moment(post.day + " " + post.time).calendar()}</div>
                            </div>
                        )}
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(Appointments);