import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';

import './AddUrgency.css';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken } from '../../Utils/Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class AntibodyDonations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            isLoaded: false,
            perpage: 0,
            checked: false,
            injectiondate: new Date(),
            batchNumber:"",
            medicines:"",
            sideeffects:"",
            infections:0,
            //infectionsdate: new Date(),
            fever:0,
            symptoms:"",
            antibiotics:"",
            absences:0,
            absencestype: 1
        }

        this.setValue = this.setValue.bind(this);
        this.setBatchNumber = this.setBatchNumber.bind(this);
        this.setMedicines = this.setMedicines.bind(this);
        this.setSideeffects = this.setSideeffects.bind(this);

        this.setInfectionsdate = this.setInfectionsdate.bind(this);
        this.setFever = this.setFever.bind(this);
        this.setSymptoms = this.setSymptoms.bind(this);
        this.setAntibiotics = this.setAntibiotics.bind(this);
        this.setAbsences = this.setAbsences.bind(this);

    }

    setValue(p) { this.setState({injectiondate: p}); }
    setBatchNumber(p) { this.setState({batchNumber: p.target.value}); }
    setMedicines(p) { this.setState({medicines: p.target.value}); }
    setSideeffects(p) { this.setState({sideeffects: p.target.value}); }

    setInfectionsdate(p) { this.setState({infectionsdate: p}); }
    setFever(p) { this.setState({fever: p.target.value}); }
    setSymptoms(p) { this.setState({symptoms: p.target.value}); }
    setAntibiotics(p) { this.setState({antibiotics: p.target.value}); }
    setAbsences(p) { this.setState({absences: p.target.value}); }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/abd/all/', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.ige, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        //checked, injectiondate,batchNumber,medicines,sideeffects,infections, infectionsdate, fever, symptoms, antibiotics, absences, absencestype
        const { posts, isLoaded} = this.state;
/*
        const handleChange = (e) => {
            if (checked === false) {
                this.setState({ checked: true })
            } else {
                this.setState({ checked: false })
            }
        };

        const handleSubmit = event => {
            
            event.preventDefault();
            let dagurinn = moment(injectiondate).format('yyyy-MM-DD');
            const jsonbody = JSON.stringify({
                injectiondate: dagurinn,
                batchNumber: batchNumber,
                medicines: medicines,
                sideeffects: sideeffects,
                infections: infections,
                infectionsdate: dagurinn,
                fever: fever,
                symptoms: symptoms,
                antibiotics: antibiotics,
                absences: absences,
                absencestype: absencestype,
            });
            const customConfig = {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }, crossdomain: true
            };

            axios.post(global.config.api.url.live + '/abd', jsonbody, customConfig )
            .then(res => {
                    if (res.data.statusCode === 201) {
                        window.location = "/notandi/antibodydonations/";
                    } else {
                        console.log("Villa: " + res.data.statusCode)
                    }
                }
            )
            .catch(error => {
                const response = error.response;
                console.log("Villa: " + response);
            })
        };
        */

        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <Topbar title='menu.medicine_reminder' heiti=""/>
                        <main>
                            <h2>Dagbók fyrir mótefnagjafir undir húð</h2>
                            <img src='/img/stungusvaedi.png' width="400" className='responsiveimg fr' alt='Stungustaðir' />
                            <p >Hver síða sýnir vikutímabil, á hverri síðu skal skrá dagsetningu mótefnagjafar, lotunúmer, skammt í ml og hugsanlegar aukaverkanir. Ef þú tekur lyf samdægurs til að koma í veg fyrir aukaverkanir eða til þess að milda aukaverkanir skal skrá það niður. Sýkingar, veikindi og fjarvistir frá vinnu/skóla eru einnig gagnlegar upplýsingar til þess að meta gagnsemi meðferðar. Einnig skaltu skrá ef þú ert á sýklalyfjum.</p>
                            <h3>Stungustaðir</h3>
                            <p>
                                <strong className='graenusvaedin'>Grænu svæðin</strong> sýna stungustaði sem er æskilegast að nota<br/>
                                <strong className='blausvaedin'>Bláu svæðin</strong> sýna stungustaði sem er einnig hægt að nota
                            </p>
                            <h3 className='dagbokinfo'>Val á stungustað:</h3>
                            <img src='/img/vokvasettin.png' width="400" className='responsiveimg fr' alt='Vökvasettin' />
                            <ul className='dagbokinfo'>
                                <li>Ekki velja stungustað þar sem húðin er rauð, hörð, marin eða aum.</li>
                                <li>Ekki velja stungustað á húðsvæði með örum eða sliti.</li>
                                <li>Hafðu 5 cm á milli stungustaða og 5 cm frá nafla.</li>
                            </ul>
                            <p className='dagbokinfo'>Vökvasettin er hægt að fá með 1-4 örmum. Innrennslismagnið á hverjum stungustað er breytilegt, en mælt er með því að skipta niður skömmtum sem eru 30 ml eða meira á nokkra stungustaði.</p>
                            <div className='bigdivider'></div>
                            <h1>Skráningar <a class="addUrgency" href="/notandi/antibodydonations/add"><i class="fa-solid fa-square-plus" aria-hidden="true"></i> Skrá mótefnagjöf</a></h1>
                            <table className='qualityoflife'>
                                <thead>
                                    <tr>
                                        <th>Dagsetting</th>
                                        <th>Lotunúmer og lyfjaskammtur</th>
                                        <th className='hideonmobile'>Lyf tengd lyfjagjöf</th>
                                        <th className='hideonmobile'>Aukaverkanir</th>
                                        <th>Sýking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {posts.map(post =>
                                    <tr key={post.id}>
                                        <td>{moment(post.infectionsdate).format('Do MMM YYYY')}</td>
                                        <td >{post.batchNumber}</td>
                                        <td className='hideonmobile'>{post.medicines}</td>
                                        <td className='hideonmobile'>{post.sideeffects}</td>
                                        <td>{post.symptoms}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Topbar title='menu.medicine_reminder' heiti=""/>
                        <main>
                        <h2 className='dagbok'>Dagbók fyrir mótefnagjafir undir húð</h2>
                            <p className='lysing'>Þessi dagbók er fyrir þig sem ert í mótefnameðferð í heimahúsi. Hér skráir þú sjálf/ur mótefnagjafir, hvernig þér líður eftir gjöfina og hvernig líðan þín er á milli gjafa. Tilgangur skráningar er gefa betri sýn á eigið heilsufar. Þetta gagnast bæði þér og fagfólki til að meta árangur meðferðar.</p>
                            <p>Sjá leiðbeningar <a href="/notandi/fraedsluefni/dagbok-fyrir-motefni">hér</a></p>
                            <p className='lysing'><strong>Það er mikilvægt að þú skráir lotunúmer á lyfjaglasi við hverja mótefnagjöf.</strong></p>
                            <h1>Skráningar <a class="addUrgency" href="/notandi/hae/lifsgaedi/new"><i class="fa-solid fa-square-plus" aria-hidden="true"></i> Skrá mótefnagjöf</a></h1>
                            <table className='qualityoflife'>
                                <thead>
                                    <tr>
                                        <th>Dagsetting</th>
                                        <th>Lotunúmer og lyfjaskammtur</th>
                                        <th>Lyf tengd lyfjagjöf</th>
                                        <th>Aukaverkanir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ekkert skráð</td>
                                        <td ></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </main>
                    </div>
                )
            }
        } else {
            return (
                <div>
                        <Topbar title='menu.medicine_reminder' heiti=""/>
                        <main>
                            <div className='seventycent'>
                                Hleð inn.....
                            </div>
                        </main>
                    </div>
            )
        }
    }
}


export default withTranslation()(AntibodyDonations);