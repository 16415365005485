import React, { useState } from 'react';

function Nyskra(props) {
    const [name, setName] = useState("");
    const [netfang, setNetfang] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let res = await fetch("/users", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                crossdomain: true,
                body: JSON.stringify({
                    fullname: name,
                    username: username,
                    password: password,
                    netfang: netfang,
                }),
            });

            let resJson = await res.json();
            console.log(resJson);
            if (res.status === 201) {
                setName("");
                setPassword("");
                setNetfang("");
                setUsername("");
                setMessage("Þetta gekk allt eins og í sögu!");
        } else {
            setMessage("Ekki tókst að búa til notanda!");
        }
    } catch (err) {
        console.log(err);
    }
};

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Mitt svæði</h3>
                <h2 className="nyskra">Nýskráning</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" value={name} placeholder="Nafn" onChange={(e) => setName(e.target.value)} />
                    <input type="text" value={netfang} placeholder="Netfang" onChange={(e) => setNetfang(e.target.value)} />
                    <input type="text" value={username} placeholder="Notendanafn" onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" value={password} placeholder="Lykilorð" onChange={(e) => setPassword(e.target.value)} />
                    <button type="submit" className="buatilnotenda">Búa til!</button>
                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
            </div>
        </main>
    );
}

export default Nyskra;