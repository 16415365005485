import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import { Link } from 'react-router-dom';
export class EducationalMaterial extends Component {
    render() {
        return (
            <div>
                <Topbar title="Fræðsluefni" heiti=""/>
                <main>
                    <div className='fraeefnid'>
                        <h1>Ónæmisgallar Hvað er hægt að gera til að draga úr sýkingum?</h1>
                        <h4>Sjúklingafræðsla | Ágúst 2022</h4>
                        <p>Meðfæddir og áunnir ónæmisgallar eru orð sem notuð eru yfir marga sjúkdóma sem koma fram í ónæmiskerfinu. Ónæmiskerfið hjálpar líkamanum að vinna á sýkingum af völdum örvera, svo sem baktería, vírusa, sveppa og frumdýra. Fólki með ónæmisgalla er hættara við sýkingum og þær geta orðið alvarlegar og erfiðar við að eiga.</p>
                        <p>Sumir ónæmisgallar eru frekar vægir en aðrir alvarlegri. Venjulega koma meðfæddir ónæmisgallar í ljós strax á barnsaldri en stundum ekki fyrr en á fullorðinsárum.</p>
                        <h3>Að fyrirbyggja sýkingar</h3>
                        <p>Ýmislegt er hægt að gera til að verjast sýkingum. Heilbrigt líferni, bólusetningar og sýkingavarnir eru atriði sem vega þungt í kraftmiklu ónæmissvari og þeir sem eru með skertar ónæmisvarnir eru sérstaklega hvattir til að huga að þessum atriðum. Hér er fjallað um sýkingavarnir og bólusetningar.</p>
                        
                        <h3>Sýkingar</h3>
                        <p>Sýkingar geta komið fram víða í líkamanum (mynd 1).
                        Mikilvægt er að hafa samband við heilbrigðisstarfsmann
                        þegar þú finnur fyrir einkennum sýkingar. Með því að hefja
                        meðferð snemma er hægt að draga úr líkum á langvarandi
                        sýkingu.</p>
                        <div class="greininfo">
                            <h3>Einkenni sýkinga eru meðal annars:</h3>
                            <ul>
                                <li>Almennur slappleiki og vanlíðan</li>
                                <li>Hrollur, sviti og/eða hiti</li>
                                <li>Nýir eða versnandi verkir</li>
                                <li>Stíflað nef og nefrennsli</li>
                                <li>Hálssærindi eða sár í munnholi</li>
                                <li>Mæði og/eða hósti, slímugur uppgangur</li>
                                <li>Gröftur</li>
                                <li>Útferð eða eymsli í leggöngum</li>
                                <li>Roði eða eymsli í húð, sárum eða í skurðsári eftir aðgerð</li>
                                <li>Uppköst eða niðurgangur</li>
                                <li>Tíð þvaglát eða sviði við þvaglát</li>
                            </ul>
                        </div>

                        <h3>Húðin</h3>
                        <p>Húðin er varnarhjúpur líkamans og hindrar að örverur, t.d. vírusar og bakteríur, komist þangað í líkamann</p>
                        <ul>
<li>sem þau eiga ekki erindi. Því er mikilvægt að hlúa að húðinni og halda henni heilli.</li>
<li>Nota rakakrem til að forðast að húðin verði þurr og springi.</li>
<li>Vera vakandi fyrir breytingum á húð svo sem roða, verkjum, hita, útbrotum og sárum.</li>
<li>Halda sárum og skrámum hreinum þar sem þau opna leiðir fyrir sýkla inn í líkamann.</li>
</ul>
<h3>Slímhúð</h3>
<p>Slímhúðin er stærsta líffæri ónæmiskerfisins og einn mikilvægasti hluti þess ásamt eitlum. Slímhúðin þekur
augun, öndunarfærin, meltingarfærin, þvagfæri og æxlunarfæri að innan. Hún gefur frá sér margvísleg efni
og slím sem m.a. hindrar vöxt örvera og virkjar ónæmiskerfið. Auk þess getur hún hindrað för örvera inn í
vefi líkamans.</p>
<p>Í tárum, slími í nefi, munnvatni og sýrum í meltingarkerfinu er einnig að finna efni sem geta eytt eða hamlað
vexti örvera. Þá eru nauðsynlegir góðgerlar á yfirborði slímhúðarinnar sem hindra innrás hættulegra örvera,
styrkja slímhúðina og stuðla að jafnvægi ónæmiskerfisins. Því er mikilvægt að viðhalda heilbrigðri slímhúð
þar sem ójafnvægi í starfsemi hennar getur leitt til m.a. sýkinga, ofnæmis og bólgusjúkdóma.</p>

                        <Link to="/notandi/fraedsluefni" className='lesameira'>Til baka í <strong>Fræðsluefni</strong></Link>
                    </div>
                </main>
            </div>
        )
    }
}

export default EducationalMaterial;