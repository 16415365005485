import React, { Component } from 'react'

import axios from 'axios';
import { getToken } from  '../../../../Utils/admincommon';
import { withTranslation} from 'react-i18next';
import { decrypt, encrypt } from '../../../helpers/decrypt'

const token = getToken();

export class PatientForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            name: 0,
            kyn: "",
            tel: "",
            ssn: "",
            netfang: "",
            allergies: "",
            mydr: "",
            myhealthcare: "",
            textiatakka: "Breyta notanda",
            accessto: "",
            a_haexpert: 0,
            a_lifsgaedi:0,
            a_medicine_reminder:0,
            a_igexpert:0,
        }

        this.setName = this.setName.bind(this);
        this.setNetfang = this.setNetfang.bind(this);
        this.setSSN = this.setSSN.bind(this);
        this.setKyn = this.setKyn.bind(this);
        this.setTel = this.setTel.bind(this);
        this.setAllergies = this.setAllergies.bind(this);
        this.setMYdr = this.setMYdr.bind(this);
        this.setMYhealthcare = this.setMYhealthcare.bind(this);
        this.setTextaATakka = this.setTextaATakka.bind(this);
        this.getIfisIn = this.getIfisIn.bind(this);
        
        this.setAhaexpert = this.setAhaexpert.bind(this);
        this.setLifsgaedi = this.setLifsgaedi.bind(this);
        this.setAIgexpert = this.setAIgexpert.bind(this);
        this.setMedicinereminder = this.setMedicinereminder.bind(this);

        this.setSAhaexpert = this.setSAhaexpert.bind(this);
    }

    componentDidMount () {

        var config = {
            'Content-Type': 'application/json',
            'Authorization': token
        }

        axios.get(global.config.api.url.live + '/getpatient/'+this.props.userid, {
            param: {
                userid: this.props.userid
            },
            headers: config
        }, { crossdomain: true })
        .then(res => this.setState({
            isLoaded: true,
            user: this.props.userid,
            ssn: decrypt(res.data.data.user[0].ssn),
            kyn: res.data.data.user[0].kyn,
            tel: decrypt(res.data.data.user[0].tel),
            netfang: decrypt(res.data.data.user[0].netfang),
            name: decrypt(res.data.data.user[0].name),
            allergies: decrypt(res.data.data.user[0].allergies),
            mydr: decrypt(res.data.data.user[0].physician),
            myhealthcare: decrypt(res.data.data.user[0].healthcare),
            accessto: res.data.data.user[0].accessto,
        },this.getIfisIn(res.data.data.user[0].accessto) ) )
        .catch(error => {
			const response = error.response;
            console.log(response);
        });
        //this.getIfisIn();
    }

    setName(event) {  this.setState({name: event.target.value}); }
    setNetfang(event) {  this.setState({netfang: event.target.value}); }
    setSSN(event) {  this.setState({ssn: event.target.value}); }
    setKyn(event) {  this.setState({kyn: event.target.value}); }
    setTel(event) {  this.setState({tel: event.target.value}); }
    setAllergies(event) {  this.setState({allergies: event.target.value}); }
    setMYdr(event) {  this.setState({mydr: event.target.value}); }
    setMYhealthcare(event) {  this.setState({myhealthcare: event.target.value}); }
    setTextaATakka(p) {  this.setState({textiatakka: p}); }

    setAhaexpert(event) {  this.setState({a_haexpert: event.target.value}); }
    setLifsgaedi(event) {  this.setState({a_lifsgaedi: event.target.value}); }
    setAIgexpert(event) {  this.setState({a_igexpert: event.target.value}); }
    setMedicinereminder(event) {  this.setState({a_medicine_reminder: event.target.value}); }

    setSAhaexpert() {  this.setState({a_haexpert: 1}); }

    getIfisIn(accesstostring) {
        if (accesstostring.includes(1)) {
            this.setState({a_haexpert: 1});
        }
        if (accesstostring.includes(2)) {
            this.setState({a_lifsgaedi: 1});
        }
        if (accesstostring.includes(3)) {
            this.setState({a_medicine_reminder: 1});
        }
        if (accesstostring.includes(4)) {
            this.setState({a_igexpert: 1});
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        try {
            fetch(global.config.api.url.live + "/getpatient/"+event.target[5].value, {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    name: encrypt(event.target[0].value),
                    netfang: encrypt(event.target[1].value),
                    ssn: encrypt(event.target[3].value),
                    kyn: event.target[4].value,
                    tel: encrypt(event.target[2].value),
                    userid: event.target[5].value,
                    allergies: encrypt(event.target[6].value),
                    mydr: encrypt(event.target[7].value),
                    myhealthcare: encrypt(event.target[8].value),
                    access_to_1: event.target[9].value,
                    access_to_2: event.target[10].value,
                    access_to_3: event.target[11].value,
                    access_to_4: event.target[12].value,
                }),
            }).then((response) => {
                if (response.status === 201) {
                    window.location = "/admin/patients/" + event.target[5].value;
                }
            });

        } catch (err) {
            console.log(err);
        }
    };

    render() {
        //accessto
        const { isLoaded, name, netfang, tel, kyn, ssn, user, allergies, mydr, myhealthcare, textiatakka, a_haexpert,a_lifsgaedi,a_medicine_reminder,a_igexpert} = this.state;
        if (isLoaded) {
            return (
                <>
                    <form onSubmit={this.handleSubmit}>
                        <div className="sjuklingsform">
                            <div className='sjuklingur_upplysingar'>
                                <h3>Breyta upplýsingum</h3>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="name">Nafn</label>
                                    </div><div className='input'>
                                        <input type="text" placeholder="Nafn" value={name} id="name" onChange={this.setName} />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="netfang">Netfang</label>
                                    </div><div className='input'>
                                        <input type="text" placeholder="Netfang" value={netfang} id="netfang" onChange={this.setNetfang} />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="tel">Símanúmer</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Símanúmer" value={tel} id="tel" onChange={this.setTel} />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Kennitala</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Kennitala" value={ssn} id="ssn" onChange={this.setSSN}  />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="kyn">Kyn</label>
                                    </div><div className='input'>
                                        <select value={kyn}  id="kyn" onChange={this.setKyn}>
                                            <option value="1">Karl</option>
                                            <option value="2">Kona</option>
                                            <option value="3">Hán</option>
                                        </select>
                                    </div>
                                </div>
                                <input type="hidden" value={user} id="user" readOnly />
                            </div><div className="sjuklingur_aukaupplysingar pr20">
                                <h3>&nbsp;</h3>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Ofnæmi</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Ofnæmi" value={allergies} id="allergies" onChange={this.setAllergies}  />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Heimilislæknir</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Heimilislæknir" value={mydr} id="mydr" onChange={this.setMYdr}  />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Heilsugæsla</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Heilsugæsla" value={myhealthcare} id="myhealthcare" onChange={this.setMYhealthcare}  />
                                    </div>
                                </div>
                            </div><div className="sjuklingur_adgangur">
                                <h3>Aðgangur</h3>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">HAExpert</label>
                                    </div><div className='input'>
                                        <select value={a_haexpert} className='fw' id="a_haexpert" onChange={this.setAhaexpert}>
                                            <option value="1">Með aðgang</option>
                                            <option value="0">Ekki</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Lífsgæði</label>
                                    </div><div className='input'>
                                        <select value={a_lifsgaedi} className='fw'  id="a_lifsgaedi" onChange={this.setLifsgaedi}>
                                            <option value="1">Með aðgang</option>
                                            <option value="0">Ekki</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Lyfjadagbók</label>
                                    </div><div className='input'>
                                        <select value={a_medicine_reminder} className='fw' id="a_medicine_reminder" onChange={this.setMedicinereminder}>
                                            <option value="1">Með aðgang</option>
                                            <option value="0">Ekki</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">IgExpert</label>
                                    </div><div className='input'>
                                        <select value={a_igexpert} className='fw' id="a_igexpert" onChange={this.setAIgexpert}>
                                            <option value="1">Með aðgang</option>
                                            <option value="0">Ekki</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="buatilnotenda" id="uppfaeranotenda" onClick={() => this.setState({ textiatakka: "Er að uppfæra" })}> {textiatakka}</button>
                        </div>
                    </form>
                </>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(PatientForm);